import { CampaignFilterUI } from '../../FilterUI';
import React from 'react';
import { CampaignStatusFilterDefinition } from '../../../../../domainTypes/filters';
import {
  CampaignStatus,
  campaignStatusTitle
} from '../../../../../features/Campaigns/service';
import { ChipContent, formatList } from '../common';
import { CampaignStatusMenu } from '../Menus/Campaigns/StatusMenu';

const CampaignStatusChip: React.FC<{
  definition: CampaignStatusFilterDefinition;
}> = ({ definition }) => (
  <ChipContent title="Status is">
    {formatList(
      definition.v.map((value) => campaignStatusTitle(value as CampaignStatus))
    )}
  </ChipContent>
);

export const campaignStatusFilterUIDef: CampaignFilterUI = {
  type: 'campaign',
  dimension: 'campaign_status',
  chip: ({ definition }) => {
    if (!definition) return <>Status</>;
    if (definition.k !== 'campaign_status') {
      throw new Error('Invalid definition');
    }
    return <CampaignStatusChip definition={definition} />;
  },
  menu: function CampaignStatusFilterUIMenu({ definition, ...props }) {
    return (
      <CampaignStatusMenu
        definition={definition as CampaignStatusFilterDefinition}
        {...props}
      />
    );
  },
  init: () => ({
    k: 'campaign_status',
    v: []
  }),
  toPredicate: (definition) => {
    if (definition.k !== 'campaign_status') return () => true;
    return (c) => definition.v.includes(c.status);
  }
};
