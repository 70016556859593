import { useFeatureEnabled } from '../../../services/features';
import { useCustomDimensionsFilterUI } from '../../../components/analytics_v2/FiltersV2/FilterUI/analytics/CustomDimensionsFilterUI';
import { useMemo } from 'react';
import { ALL_UTM_FILTER_UIS } from '../../../components/analytics_v2/FiltersV2/FilterUI/analytics/UTMFilterUI';
import { referrerFilterUIDef } from '../../../components/analytics_v2/FiltersV2/FilterUI/analytics/ReferrerFilterUI';
import { payoutIdFilterUIDef } from '../../../components/analytics_v2/FiltersV2/FilterUI/analytics/PayoutIdFilterUI';
import { payoutStatusFilterUIDef } from '../../../components/analytics_v2/FiltersV2/FilterUI/analytics/PayoutStatusFilterUI';
import { channelFilterUIDef } from '../../../components/analytics_v2/FiltersV2/FilterUI/analytics/ChannelFilterUI';
import { platformFilterUIDef } from '../../../components/analytics_v2/FiltersV2/FilterUI/analytics/PlatformFilterUI';
import { transactionStatusFilterUIDef } from '../../../components/analytics_v2/FiltersV2/FilterUI/analytics/TransactionStatusFilterUI';
import { transactionTypeFilterUIDef } from '../../../components/analytics_v2/FiltersV2/FilterUI/analytics/TransactionTypeFilterUI';
import { deviceFilterUIDef } from '../../../components/analytics_v2/FiltersV2/FilterUI/analytics/DeviceFilterUI';
import { tagFilterUIDef } from '../../../components/analytics_v2/FiltersV2/FilterUI/analytics/TagFilterUIDef';
import { compact } from 'lodash';

export const usePerformancePagesFilterUIs = () => {
  const showTrafficSources = useFeatureEnabled('REFERRER_REPORTS_V1');
  const showCustomDimensions = useFeatureEnabled('CUSTOM_DIMENSIONS');
  const showPayoutDimensions = useFeatureEnabled('PAYOUTS_V1');
  const customDimensionUIs = useCustomDimensionsFilterUI();

  return useMemo(
    () =>
      compact([
        ...(showCustomDimensions ? customDimensionUIs : []),
        ...(showTrafficSources ? ALL_UTM_FILTER_UIS : []),
        showTrafficSources ? referrerFilterUIDef : null,
        ...(showPayoutDimensions
          ? [payoutIdFilterUIDef, payoutStatusFilterUIDef]
          : []),
        channelFilterUIDef,
        platformFilterUIDef,
        transactionStatusFilterUIDef,
        transactionTypeFilterUIDef,
        deviceFilterUIDef,
        tagFilterUIDef
      ]),
    [
      customDimensionUIs,
      showCustomDimensions,
      showPayoutDimensions,
      showTrafficSources
    ]
  );
};
