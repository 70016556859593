import { Card } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { useTable } from '../../../../components/analytics_v2/Table';
import { TimeframePicker } from '../../../../components/analytics_v2/Timeframe';
import { CustomPagination } from '../../../../components/CustomPagination';
import { ExportQueryButton } from '../../../../components/ExportQuery';
import { RowsRenderer } from '../../../../components/GroupableList';
import { InlineLoader, Loader } from '../../../../components/Loader';
import { ColumnSelector } from '../../../../components/Table/ColumnSelector';
import {
  AnalyticsFilter,
  SelectableField
} from '../../../../domainTypes/analytics_v2';
import { css } from '../../../../emotion';
import { Centered } from '../../../../layout/Centered';
import { FlexContainer } from '../../../../layout/Flex';
import { DEFAULT_OFFSET, PageToolbar } from '../../../../layout/PageToolbar';
import { Metric } from '../../../../services/analyticsV2/metrics';
import { EmptySearchResults, GeneralError } from './Messages';
import { SearchSummary } from './SearchSummary';
import { SelectionToolbar } from './SelectionToolbar';
import {
  PAGE_SIZE,
  SearchBox,
  useContentFilters,
  useExportQuery,
  usePagesCount,
  usePagesMetrics
} from './service';
import { columnDefinitions, useColumns } from './Table/columns';
import { useHasComparison } from '../../../../hooks/timeframe';
import { useContentPagesFilterUIs } from '../../services/filters';
import { FILTER_STATE_LOCAL_STORAGE_KEYS } from '../../../../components/analytics_v2/FiltersV2/Drawer/keys';
import { useAnalyticsFilters } from '../../../../components/analytics_v2/FiltersV2/useAnalyticsFilters';
import { FiltersToggleButton } from '../../../../components/analytics_v2/FiltersV2/Toggle';
import { FiltersDrawer } from '../../../../components/analytics_v2/FiltersV2/Drawer/FiltersDrawer';
import { useSortQueryParam } from '../../../../components/analytics_v2/Table/hooks';

export interface PageSelectionState {
  pages: Set<string>;
  togglePage: (page: string) => void;
  toggleAllPages: () => void;
  isAllPagesSelected: boolean;
  allPagesCount: number;
  resetPages: () => void;
}

const useSelectionState = (filters: AnalyticsFilter[]) => {
  const [pagesCount = 1] = usePagesCount(filters);

  const [pages, setPages] = useState<Set<string>>(new Set());
  const [allPages, setAllPages] = useState(false);

  return useMemo<PageSelectionState>(() => {
    const toggleAllPages = () => {
      setAllPages((v) => !v);
    };
    const togglePage = (page: string) => {
      const nextValue = new Set(pages);
      if (nextValue.has(page)) {
        nextValue.delete(page);
      } else {
        nextValue.add(page);
      }
      setPages(nextValue);
    };
    const resetPages = () => {
      setPages(new Set());
      setAllPages(false);
    };
    return {
      pages,
      togglePage,
      toggleAllPages,
      isAllPagesSelected: allPages,
      resetPages,
      allPagesCount: pagesCount
    };
  }, [allPages, pages, pagesCount]);
};

const SORT_PARAM_NAME = 'sort';
const DEFAULT_SORT_COLUMN = {
  column: 'c' as const,
  direction: 'desc' as const
};

const useSortColumn = (columns: string[]) => {
  const [[sortColumn]] = useSortQueryParam(
    SORT_PARAM_NAME,
    columns,
    DEFAULT_SORT_COLUMN
  );
  return sortColumn;
};

export const ContentOverviewBody = () => {
  const { available, defaultVisible } = useColumns();
  /*
   NOTE:
    This is used to avoid circular dependency on `orderBy` returned from `useTable`.
    In general, here we have a cycle between:
      `useAnalyticsFilters` (need sort column, and provides filters)
      `useTable` (need selection state (via columnDefs), and provides sort column)
      `useSelectionState` (need filters, provides selection state)
  */
  const sort = useSortColumn(defaultVisible);

  const showComparison = useHasComparison();

  const filterUIs = useContentPagesFilterUIs();
  const { filters, toggleProps, drawerProps } = useAnalyticsFilters(
    filterUIs,
    {
      orderBy: sort as Metric
    },
    {
      localStorageKey: FILTER_STATE_LOCAL_STORAGE_KEYS.content
    }
  );

  const contentFilters = useContentFilters(filters);

  const {
    allPagesCount,
    pages,
    isAllPagesSelected,
    resetPages,
    togglePage,
    toggleAllPages
  } = useSelectionState(contentFilters);

  const columnDefs = useMemo(
    () =>
      columnDefinitions({
        allPagesCount,
        toggleAllPages,
        togglePage,
        pages,
        isAllPagesSelected
      }),
    [allPagesCount, isAllPagesSelected, pages, toggleAllPages, togglePage]
  );

  const {
    tableProps,
    columnSelectorProps,
    paginationSelectorProps,
    pagination,
    orderBy,
    metrics
  } = useTable(available, columnDefs, {
    pageSize: PAGE_SIZE,
    defaultSortColumn: DEFAULT_SORT_COLUMN.column,
    defaultSortDirection: DEFAULT_SORT_COLUMN.direction,
    sortQueryParamName: SORT_PARAM_NAME,
    defaultVisibleColumns: defaultVisible,
    showComparison
  });

  const normalizedColumnSelectorProps = useMemo(() => {
    return {
      ...columnSelectorProps,
      columns: columnSelectorProps.columns.filter(
        (c) => !('key' in c) || c.key !== 'selector'
      )
    };
  }, [columnSelectorProps]);

  const [data, loading, error] = usePagesMetrics(
    contentFilters,
    metrics,
    pagination,
    orderBy
  );

  const hasActiveSelection = pages.size > 0 || isAllPagesSelected;
  const tableHeadOffset = DEFAULT_OFFSET - 1;
  const exportQuery = useExportQuery(contentFilters, metrics, orderBy);

  if (error) {
    return <GeneralError />;
  }

  return (
    <>
      <PageToolbar
        className={css(() => ({ alignItems: 'baseline', paddingTop: 0 }))}
      >
        {hasActiveSelection ? (
          <SelectionToolbar
            pages={pages}
            isAllPagesSelected={isAllPagesSelected}
            resetPages={resetPages}
            allPagesCount={allPagesCount}
            filters={contentFilters}
          />
        ) : (
          <div style={{ width: '100%' }}>
            <FlexContainer
              direction="row"
              justifyContent="space-between"
              marginBottom={2}
            >
              <FlexContainer spacing={0.5}>
                <SearchBox
                  autoFocus
                  placeholder="Search by URL or slug"
                  width={450}
                  size="small"
                />
                <FiltersToggleButton {...toggleProps} />
              </FlexContainer>

              <FlexContainer justifyContent="flex-end">
                {data && loading && <InlineLoader />}
                <ColumnSelector {...normalizedColumnSelectorProps} />
                <CustomPagination
                  {...paginationSelectorProps}
                  count={Math.ceil(allPagesCount / PAGE_SIZE)}
                  siblingCount={0}
                />
                <TimeframePicker />
                <ExportQueryButton
                  title="Export pages to CSV"
                  reportType="content"
                  query={exportQuery}
                />
              </FlexContainer>
            </FlexContainer>

            <FiltersDrawer {...drawerProps} title="Filter content by" />
          </div>
        )}
      </PageToolbar>

      {!data ? (
        <Card>
          <Centered height={350}>
            <Loader size={32}></Loader>
          </Centered>
        </Card>
      ) : data.length === 0 ? (
        <EmptySearchResults />
      ) : (
        <>
          <SearchSummary
            filters={contentFilters}
            metrics={metrics}
            orderByField={orderBy.field as SelectableField}
          />
          <RowsRenderer
            {...tableProps}
            renderHead={true}
            headProps={{
              sticky: true,
              offset: tableHeadOffset
            }}
            rows={data}
            rowToKey={(d) => d.group.page_url}
          />
        </>
      )}
    </>
  );
};
