import { useDomains } from '../../../services/domains';
import { ComponentProps, useMemo } from 'react';
import { coerceFilterMode, FilterMode } from '../../../domainTypes/filters';
import {
  AnalyticsFilter,
  ISOTimeRange
} from '../../../domainTypes/analytics_v2';
import { useTimeframe } from '../Timeframe';
import { Metric } from '../../../services/analyticsV2/metrics';
import { FiltersToggleButton } from './Toggle';
import {
  AnalyticsFilterUI,
  AnalyticsFilterUIContext,
  FilterUI,
  FilterUIMenuProps
} from './FilterUI';
import { FiltersDrawer } from './Drawer/FiltersDrawer';
import { compact } from 'lodash';
import {
  DrawerOptions,
  useFilterDrawerState,
  useFiltersDrawer,
  useFiltersUrlParam
} from './hooks';

type QueryParts = Partial<{
  filters: Array<AnalyticsFilter>;
  orderBy: Metric;
  range: ISOTimeRange;
}>;

const useAnalyticsContext = (
  queryParts: QueryParts = {}
): AnalyticsFilterUIContext => {
  const { range } = useTimeframe();
  return useMemo(
    () => ({
      baseQuery: {
        filters: queryParts.filters ?? [],
        orderBy: queryParts.orderBy ?? 'c', // TODO: create full AnalyticsOrderBy here
        range: queryParts.range ?? range
      }
    }),
    [queryParts, range]
  );
};

interface AnalyticsFiltersProps {
  filters: AnalyticsFilter[];
  drawerProps: ComponentProps<typeof FiltersDrawer>;
  toggleProps: ComponentProps<typeof FiltersToggleButton>;
}

export function useAnalyticsFilters(
  uis: Array<AnalyticsFilterUI>,
  queryParts: QueryParts = {},
  drawerOptions?: DrawerOptions
): AnalyticsFiltersProps {
  const [filters, setFilters] = useFiltersUrlParam();
  const { isOpen, toggle } = useFilterDrawerState(drawerOptions);
  const filtersDrawerProps = useFiltersDrawer(filters, setFilters);

  const domains = useDomains();
  const filterClausesWithDimensions = useMemo(() => {
    const context = { domains };
    return compact(
      filters.map((definition) => {
        const dimension = definition.k;
        const ui = uis.find((ui) => ui.dimension === dimension);
        if (!ui) {
          return null;
        }
        return {
          dimension,
          clauses: ui.toClauses(definition, context)
        };
      })
    );
  }, [domains, filters, uis]);

  const filterClauses = useMemo(
    () => filterClausesWithDimensions.flatMap(({ clauses }) => clauses),
    [filterClausesWithDimensions]
  );

  const baseContext = useAnalyticsContext(queryParts);

  const filterUIs = useMemo<Array<FilterUI>>(() => {
    return uis.map((ui: AnalyticsFilterUI) => {
      const Menu = ui.menu;
      const otherFiltersClauses = filterClausesWithDimensions
        .filter(({ dimension }) => dimension !== ui.dimension)
        .flatMap(({ clauses }) => clauses);
      const filters = [
        ...baseContext.baseQuery.filters,
        ...otherFiltersClauses
      ];
      const context = {
        ...baseContext,
        baseQuery: { ...baseContext.baseQuery, filters }
      };
      return {
        ...ui,
        menu: (props: FilterUIMenuProps) => (
          <Menu {...props} context={context} />
        )
      };
    });
  }, [baseContext, filterClausesWithDimensions, uis]);

  return useMemo(
    () => ({
      filters: filterClauses,
      drawerProps: {
        isOpen,
        ...filtersDrawerProps,
        filters,
        uis: filterUIs
      },
      toggleProps: {
        isOpen,
        toggle,
        filters
      }
    }),
    [filterClauses, isOpen, filtersDrawerProps, filters, filterUIs, toggle]
  );
}

export const modeToCondition = (mode?: FilterMode): 'in' | 'not in' => {
  switch (coerceFilterMode(mode)) {
    case 'in':
      return 'in';
    case 'not_in':
      return 'not in';
  }
};

export interface AnalyticsFilterClauseContext {
  domains: string[];
}
