import { Card } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { AlertBox } from '../../../../components/AlertBox';
import {
  ColumnDefinitions,
  TableMetadata,
  useTable
} from '../../../../components/analytics_v2/Table';
import { LINKS_REPORT_COLUMNS } from '../../../../components/analytics_v2/Table/columnSets';
import {
  TimeframePicker,
  useTimeframe
} from '../../../../components/analytics_v2/Timeframe';
import { CustomPagination } from '../../../../components/CustomPagination';
import { ExportQueryButton } from '../../../../components/ExportQuery';
import { RowsRenderer } from '../../../../components/GroupableList';
import { LazyRenderWrapper } from '../../../../components/LazyRenderWrapper';
import { InlineLoader, Loader } from '../../../../components/Loader';
import { NoPermissions } from '../../../../components/NoPermissions';
import { SearchInput } from '../../../../components/SearchInput';
import { ColumnSelector } from '../../../../components/Table/ColumnSelector';
import {
  AnalyticsFilter,
  AnalyticsOrderBy,
  AnalyticsQuery,
  AnalyticsResponseRowWithComparison
} from '../../../../domainTypes/analytics_v2';
import { css } from '../../../../emotion';
import { useHasComparison } from '../../../../hooks/timeframe';
import { Centered } from '../../../../layout/Centered';
import { FlexContainer } from '../../../../layout/Flex';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarSection
} from '../../../../layout/PageToolbar';
import { useStringQueryParam } from '../../../../routes';
import { useChannelIdGrouper } from '../../../../services/analyticsV2/groups';
import { Metric } from '../../../../services/analyticsV2/metrics';
import { useAnalyticsQueryV2 } from '../../../../services/analyticsV2/query';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../services/currentUser';
import { useMappedLoadingValue } from '../../../../services/db';
import {
  useMixpanel,
  useTrackMixpanelView
} from '../../../../services/mixpanel';
import { LinkActionsMenu } from '../../components/LinkActionsMenu';
import { PageBodyLinks } from '../../components/PageBodyLinks';
import { ProductLinkCellWithPartnerLazy } from '../Overview/components/ProductLinkCell';
import { LinkDetailsModal } from './LinkDetailsModal';
import { LinkTotals } from './LinkTotals';
import { toLinkSearch, useLinksFilters } from './service';
import { FiltersToggleButton } from '../../../../components/analytics_v2/FiltersV2/Toggle';
import { FiltersDrawer } from '../../../../components/analytics_v2/FiltersV2/Drawer/FiltersDrawer';
import { useAnalyticsFilters } from '../../../../components/analytics_v2/FiltersV2/useAnalyticsFilters';
import { useContentPagesFilterUIs } from '../../../Content/services/filters';
import { useMemo } from 'react';
import { FILTER_STATE_LOCAL_STORAGE_KEYS } from '../../../../components/analytics_v2/FiltersV2/Drawer/keys';

const customColumns = ['link_id', 'actions'] as const;
type CustomColumns = typeof customColumns[number];
type Column = CustomColumns | Metric;

const availableColumns: Column[] = [
  'link_id',
  ...LINKS_REPORT_COLUMNS,
  'actions'
];

const DEFAULT_VISIBLE_COLUMN_NAMES: Metric[] = [
  'c',
  'commission_sum_net',
  'quantity_net',
  'avg_rate_net',
  'epc_net'
];

const defaultVisibleColumns: Column[] = [
  ...customColumns,
  ...DEFAULT_VISIBLE_COLUMN_NAMES
];

const columnDefinitions: ColumnDefinitions<CustomColumns> = {
  link_id: {
    column: {
      key: 'link_id',
      head: () => 'Link name or deeplink',
      cell: (p: AnalyticsResponseRowWithComparison, o: TableMetadata) => (
        <LazyRenderWrapper height={40}>
          <ProductLinkCellWithPartnerLazy
            spaceId={o.spaceId}
            productId={p.group.link_id}
          />
        </LazyRenderWrapper>
      ),
      align: 'left',
      sortable: false,
      defaultDirection: 'asc',
      width: 400,
      flexGrow: 8
    },
    sorter: {
      key: 'link_id',
      items: {
        sort: (p: AnalyticsResponseRowWithComparison) => p.group.link_id,
        dir: 'asc'
      }
    }
  },
  actions: {
    column: {
      key: 'actions',
      head: () => 'Actions',
      cell: (p: AnalyticsResponseRowWithComparison, o: TableMetadata) => (
        <LinkActionsMenu linkId={p.group.link_id} spaceId={o.spaceId} />
      ),
      align: 'center',
      sortable: false,
      width: 55
    },
    sorter: {
      key: 'actions',
      items: {
        sort: () => '',
        dir: 'asc'
      }
    }
  }
};

const EXPORT_MAX_ROW_COUNT = 1000;
const PAGE_SIZE = 20;

const useLinksCount = (filters: AnalyticsFilter[], linkSearch = '') => {
  const { space } = useCurrentUser();
  const { columnTransformers } = useChannelIdGrouper();
  const { range } = useTimeframe();
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      select: ['count_uniq_link_id'],
      range,
      filters,
      search: toLinkSearch(linkSearch),
      columnTransformers: columnTransformers(space)
    };
  }, [range, filters, columnTransformers, space, linkSearch]);

  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (data) => data.rows[0]?.data.count_uniq_link_id?.curr ?? 0
  );
};

const useLinksQuery = (
  filters: AnalyticsFilter[],
  metrics: Metric[],
  paginate: AnalyticsQuery['paginate'],
  orderBy: AnalyticsOrderBy,
  linkSearch = ''
) => {
  const { space } = useCurrentUser();
  const tf = useTimeframe();
  const { columnTransformers } = useChannelIdGrouper();
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      groupBy: ['link_id'],
      select: metrics,
      ...tf,
      filters,
      search: toLinkSearch(linkSearch),
      paginate,
      orderBy: [orderBy],
      columnTransformers: columnTransformers(space)
    };
  }, [
    metrics,
    tf,
    filters,
    paginate,
    orderBy,
    columnTransformers,
    space,
    linkSearch
  ]);

  return useAnalyticsQueryV2(space.id, query, {
    logMode: 'compact',
    logLabel: 'useLinksQuery'
  });
};

const useLinksExportQuery = (
  filters: AnalyticsFilter[],
  metrics: Metric[],
  orderBy: AnalyticsOrderBy,
  linkSearch = ''
) => {
  const { space } = useCurrentUser();
  const { columnTransformers } = useChannelIdGrouper();
  const { range } = useTimeframe();
  return useMemo<AnalyticsQuery>(() => {
    return {
      groupBy: ['link_id', 'link_url'],
      select: [...metrics, 'agg_uniq_page_url'],
      range,
      filters,
      search: toLinkSearch(linkSearch),
      paginate: {
        page: 1,
        limit: EXPORT_MAX_ROW_COUNT
      },
      orderBy: [orderBy],
      columnTransformers: columnTransformers(space)
    };
  }, [metrics, range, filters, orderBy, columnTransformers, space, linkSearch]);
};

const LinksOverview = () => {
  const mixpanel = useMixpanel();
  const [linkId, setLinkId] = useStringQueryParam('link_id', '');
  const showComparison = useHasComparison();

  const {
    tableProps,
    columnSelectorProps,
    paginationSelectorProps,
    pagination,
    orderBy,
    metrics
  } = useTable(availableColumns, columnDefinitions, {
    pageSize: PAGE_SIZE,
    defaultSortColumn: 'c',
    defaultVisibleColumns,
    showComparison,
    trackingEventNames: {
      onSortChange: 'links_overview_v2_table_sort_change',
      onPageChange: 'links_overview_v2_table_page_change'
    }
  });

  const filterUIs = useContentPagesFilterUIs();
  const { filters, drawerProps, toggleProps } = useAnalyticsFilters(
    filterUIs,
    {
      orderBy: orderBy.field as Metric
    },
    {
      localStorageKey: FILTER_STATE_LOCAL_STORAGE_KEYS.links
    }
  );
  const linksFilters = useLinksFilters(filters);
  const [linkSearch, setLinkSearch] = useStringQueryParam('q');

  const [data, loading] = useLinksQuery(
    linksFilters,
    metrics,
    pagination,
    orderBy,
    linkSearch
  );
  const [count] = useLinksCount(linksFilters, linkSearch);
  const exportQuery = useLinksExportQuery(
    linksFilters,
    metrics,
    orderBy,
    linkSearch
  );
  const exportRowLimitReached = count && count >= EXPORT_MAX_ROW_COUNT;

  return (
    <>
      <PageToolbar>
        <PageToolbarSection flex={4}>
          <FlexContainer>
            <SearchInput
              size="small"
              width={360}
              placeholder="Filter by link name or URL"
              value={linkSearch}
              onChange={(nextLinkSearch) => {
                setLinkSearch(nextLinkSearch);
                if (nextLinkSearch) {
                  mixpanel.track('links_overview_v2_search', {
                    term: nextLinkSearch
                  });
                }
              }}
            />
            <FiltersToggleButton {...toggleProps} />
          </FlexContainer>
        </PageToolbarSection>
        <PageToolbarSection flex={4} justifyContent="flex-end">
          {data && loading && <InlineLoader />}
          <ColumnSelector {...columnSelectorProps} />
          <CustomPagination
            {...paginationSelectorProps}
            siblingCount={0}
            count={Math.ceil((count || 1) / PAGE_SIZE)}
          />
          <TimeframePicker />
          <ExportQueryButton
            title="Export links to CSV"
            reportType="links"
            query={exportQuery}
          >
            {exportRowLimitReached ? (
              <AlertBox variant="error">
                This export will include the first <strong>1000 rows</strong>{' '}
                matching your search criteria. Need more? Please contact the
                Customer Success team for support.
              </AlertBox>
            ) : null}
          </ExportQueryButton>
        </PageToolbarSection>
      </PageToolbar>
      <div
        className={css((t) => ({
          marginBottom: t.spacing(2),
          width: '100%'
        }))}
      >
        <FiltersDrawer {...drawerProps} title="Filter links by" />
      </div>
      {(linkSearch !== '' || filters.length > 0) && (
        <LinkTotals
          linkSearch={linkSearch}
          mode="links"
          filters={linksFilters}
        />
      )}
      {!data ? (
        <Card>
          <Centered height={350}>
            <Loader size={32} />
          </Centered>
        </Card>
      ) : (
        <RowsRenderer
          {...tableProps}
          renderHead={true}
          headProps={{
            sticky: true,
            offset: DEFAULT_OFFSET
          }}
          rows={data.rows}
          onRowClick={(d) => {
            const nextLinkId = d.group.link_id;
            setLinkId(nextLinkId);
            mixpanel.track('view_links_details_modal', { linkId: nextLinkId });
          }}
          rowToKey={(d) => d.group.link_id}
        />
      )}
      <LinkDetailsModal
        initialPageSearch={''}
        open={linkId !== ''}
        onClose={() => setLinkId('')}
        linkId={linkId}
      />
    </>
  );
};

export const PageLinksOverviewV2 = () => {
  const [canView] = useHasCurrentUserRequiredScopes(['reports.links.view']);
  useTrackMixpanelView('view_links_overview_v2');

  return (
    <>
      <Helmet>
        <title>Links | Affilimate</title>
      </Helmet>
      <PageBodyLinks noTopPadding>
        {canView ? <LinksOverview /> : <NoPermissions />}
      </PageBodyLinks>
    </>
  );
};
