import { isEqual, sortBy } from 'lodash';
import pluralize from 'pluralize';
import React, { useCallback, useMemo, useState } from 'react';
import {
  AnalyticsFilter,
  AnalyticsQuery,
  ISOTimeRange
} from '../../../../../domainTypes/analytics_v2';
import { PayoutIdFilterDefinition } from '../../../../../domainTypes/filters';
import { useAnalyticsQueryV2 } from '../../../../../services/analyticsV2/query';
import { useCurrentUser } from '../../../../../services/currentUser';
import { useMappedLoadingValue } from '../../../../../services/db';
import { Loader } from '../../../../Loader';
import { toSearchRegexp } from '../../../../SearchInput';
import { BASIC_MODES, FilterMenu } from './FilterMenu';
import { OptionsList, SelectorLoader, SelectorShell, toggle } from './Selector';
import { AnalyticsFilterMenuComponent } from '../../FilterUI';

interface PayoutIdMenuBodyProps {
  onChange: (value: Array<string>) => void;
  onSave: (v: PayoutIdFilterDefinition) => void;
  filters: AnalyticsFilter[];
  range: ISOTimeRange;
  value: Array<string>;
}

const NONE = 'None';

const usePayoutIds = (filters: AnalyticsFilter[], range: ISOTimeRange) => {
  const { space } = useCurrentUser();
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      range,
      select: ['commission_sum_net'], // only present in sales table
      groupBy: ['payout_id'],
      filters
    };
  }, [filters, range]);

  return useMappedLoadingValue(useAnalyticsQueryV2(space.id, query), (res) => {
    return sortBy(
      res.rows.map((row) => {
        const payoutId = row.group.payout_id || NONE;
        return {
          label: payoutId,
          value: payoutId,
          searchValue: payoutId
        };
      }),
      (x) => (x.value === NONE ? 'zzzzzzzzzzzzzz' : x.value)
    );
  });
};

const PayoutIdMenuBody: React.FC<PayoutIdMenuBodyProps> = ({
  range,
  onChange,
  filters,
  onSave,
  value
}) => {
  const [search, setSearch] = useState('');

  const [options, loading] = usePayoutIds(filters, range);

  const _toggle = useCallback(
    (platform: string) => onChange(toggle(value, platform)),
    [onChange, value]
  );

  const focus = useCallback(
    (platform: string) =>
      onSave({
        k: 'payout_id',
        v: [platform]
      }),
    [onSave]
  );

  const filteredOptions = useMemo(() => {
    const searchRe = toSearchRegexp(search);
    if (!options) {
      return [];
    }
    if (!searchRe) {
      return options;
    }
    return options.filter((o) => o.searchValue.match(searchRe));
  }, [options, search]);

  if (!options || loading) {
    return <Loader size={24} />;
  }

  return (
    <SelectorShell label="Payout IDs" search={search} setSearch={setSearch}>
      {!options || loading ? (
        <SelectorLoader />
      ) : (
        <OptionsList
          options={filteredOptions}
          selectedValues={value}
          onToggle={_toggle}
          onFocus={focus}
        />
      )}
    </SelectorShell>
  );
};

export const PayoutIdMenu: AnalyticsFilterMenuComponent<PayoutIdFilterDefinition> = ({
  definition,
  onSave,
  context,
  isFirst
}) => {
  const [mode, setMode] = useState(BASIC_MODES[0].value);
  const [value, setValue] = useState(definition.v);
  const newDefinition = useMemo<PayoutIdFilterDefinition>(
    () => ({
      k: 'payout_id',
      v: value
    }),
    [value]
  );
  const enableSave =
    newDefinition.v.length > 0 && !isEqual(definition, newDefinition);

  return (
    <FilterMenu>
      <FilterMenu.Header name={'payout ID'} isFirst={isFirst}>
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <FilterMenu.Body>
        <PayoutIdMenuBody
          filters={context.baseQuery.filters}
          range={context.baseQuery.range}
          onSave={onSave}
          onChange={setValue}
          value={value}
        />
      </FilterMenu.Body>
      <FilterMenu.Footer
        description={
          <>
            <strong>Payout ID</strong> is the unique identifier for the payout
            that corresponds to this transaction.
          </>
        }
      >
        <FilterMenu.SaveButton
          disabled={!enableSave}
          onSave={() => onSave(newDefinition)}
          label={`Filter by ${pluralize('Payout ID', value.length, true)}`}
        />
      </FilterMenu.Footer>
    </FilterMenu>
  );
};
