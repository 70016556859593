import { CampaignFilterUI } from '../../FilterUI';
import { ChipContent, formatList } from '../common';
import { CampaignTeamMenu } from '../Menus/Campaigns/TeamMenu';
import { CampaignTeamFilterDefinition } from '../../../../../domainTypes/filters';
import React from 'react';
import { useCurrentUser } from '../../../../../services/currentUser';
import { useMappedLoadingValue } from '../../../../../services/db';
import { useTeamsCollection } from '../../../../../features/Campaigns/service/teams';
import { Skeleton } from '@material-ui/lab';

const CampaignTeamsChip: React.FC<{
  definition: CampaignTeamFilterDefinition;
}> = ({ definition }) => {
  const { space } = useCurrentUser();
  const [teamNames = [], loading] = useMappedLoadingValue(
    useTeamsCollection(space.id),
    (usersPerSpace) => {
      if (!usersPerSpace) return [];
      return usersPerSpace
        .map((d) => d.data)
        .filter((d) => definition.v.includes(d.teamId))
        .map((d) => d.name);
    }
  );
  return (
    <ChipContent title={`Campaign team is`}>
      {loading ? <Skeleton variant="text" width={80} /> : formatList(teamNames)}
    </ChipContent>
  );
};

export const campaignTeamFilterUIDef: CampaignFilterUI = {
  type: 'campaign',
  dimension: 'campaign_team',
  chip: ({ definition }) => {
    if (!definition) return <>Campaign team</>;
    if (definition.k !== 'campaign_team') {
      throw new Error('Invalid definition');
    }
    return <CampaignTeamsChip definition={definition} />;
  },
  menu: function CampaignTeamFilterUIMenu({ definition, ...props }) {
    return (
      <CampaignTeamMenu
        definition={definition as CampaignTeamFilterDefinition}
        {...props}
      />
    );
  },
  init: () => ({
    k: 'campaign_team',
    v: []
  }),
  toPredicate: (definition) => {
    if (definition.k !== 'campaign_team') return () => true;
    return (c) => definition.v.includes(c.team);
  }
};
