import React, { useMemo } from 'react';
import { TableMetadata } from '../../../../../components/analytics_v2/Table';
import {
  createNameColumn,
  TopFooter,
  TopHeader,
  TopPaper,
  TopTable
} from '../../../../../components/analytics_v2/Table/TopTable/TopTable';
import { Truncated } from '../../../../../components/Truncated';
import { WithHoverIndicator } from '../../../../../components/WithHoverIndicator';
import {
  AnalyticsField,
  AnalyticsFilter,
  AnalyticsResponseRowWithComparison,
  ISOTimeRange
} from '../../../../../domainTypes/analytics_v2';
import { useRoutes } from '../../../../../routes';
import { useMixpanel } from '../../../../../services/mixpanel';
import { ProductLinkCellLazyMinimal } from '../../../../Links/pages/Overview/components/ProductLinkCell';
import {
  useRealtimeColumnTransformers,
  useRealtimeMetric,
  useSmallTableMetrics
} from '../service';
import { usePeriodRangeToTimeframe } from './PeriodSelector';

const GROUP_FIELD: AnalyticsField = 'link_id';

const cell = (p: AnalyticsResponseRowWithComparison, o: TableMetadata) => {
  const linkId = p.group[GROUP_FIELD];
  return (
    <WithHoverIndicator>
      <Truncated title={''}>
        <ProductLinkCellLazyMinimal spaceId={o.spaceId} productId={linkId} />
      </Truncated>
    </WithHoverIndicator>
  );
};

export const TopLinksTable = ({
  range,
  compare,
  color,
  filters
}: {
  range: ISOTimeRange;
  compare?: ISOTimeRange;
  color: string;
  filters: AnalyticsFilter[];
}) => {
  const mixpanel = useMixpanel();
  const [metric] = useRealtimeMetric();
  const metrics = useSmallTableMetrics();
  const columnTransformers = useRealtimeColumnTransformers(filters);
  const { ROUTES } = useRoutes();
  const timeframe = usePeriodRangeToTimeframe();
  const linksColumn = useMemo(
    () => createNameColumn(cell, 'Link name or deeplink', color, metric),
    [color, metric]
  );
  return (
    <TopPaper>
      <TopHeader
        title="Top links and products"
        description="Engagement and performance by link, shown by deeplink destination when available."
      ></TopHeader>
      <TopTable
        filters={filters}
        columnTransformers={columnTransformers}
        metrics={metrics}
        range={range}
        compareRange={compare}
        metric={metric}
        groupField={GROUP_FIELD}
        nameColumn={linksColumn}
        rowToHref={(p) =>
          ROUTES.links.overview_v2.url({ timeframe }, p.group[GROUP_FIELD])
        }
      />
      <TopFooter
        route={ROUTES.links.overview_v2.url({ timeframe })}
        onClick={() =>
          mixpanel.track('realtime_report_view_all', {
            widget: 'links'
          })
        }
      />
    </TopPaper>
  );
};
