import { useFeatureEnabled } from '../../../services/features';
import { useMemo } from 'react';
import { ALL_UTM_FILTER_UIS } from '../../../components/analytics_v2/FiltersV2/FilterUI/analytics/UTMFilterUI';
import { referrerFilterUIDef } from '../../../components/analytics_v2/FiltersV2/FilterUI/analytics/ReferrerFilterUI';
import { channelFilterUIDef } from '../../../components/analytics_v2/FiltersV2/FilterUI/analytics/ChannelFilterUI';
import { platformFilterUIDef } from '../../../components/analytics_v2/FiltersV2/FilterUI/analytics/PlatformFilterUI';
import { deviceFilterUIDef } from '../../../components/analytics_v2/FiltersV2/FilterUI/analytics/DeviceFilterUI';
import { tagFilterUIDef } from '../../../components/analytics_v2/FiltersV2/FilterUI/analytics/TagFilterUIDef';
import { compact } from 'lodash';
import { AnalyticsFilterUI } from '../../../components/analytics_v2/FiltersV2/FilterUI';

export const useContentPagesFilterUIs = () => {
  const showTrafficSources = useFeatureEnabled('REFERRER_REPORTS_V1');
  // TODO: include page custom dimensions when we add filters for them
  return useMemo<AnalyticsFilterUI[]>(
    () =>
      compact([
        ...(showTrafficSources ? ALL_UTM_FILTER_UIS : []),
        showTrafficSources ? referrerFilterUIDef : null,
        deviceFilterUIDef,
        tagFilterUIDef,
        channelFilterUIDef,
        platformFilterUIDef
      ]),
    [showTrafficSources]
  );
};
