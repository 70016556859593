import { CampaignFilterUI } from '../../FilterUI';
import { ChipContent, formatList } from '../common';
import { CampaignAdvertiserFilterDefinition } from '../../../../../domainTypes/filters';
import {
  CampaignAdvertiserMenu,
  useAdvertisersById
} from '../Menus/Campaigns/AdvertiserMenu';
import React from 'react';
import { Skeleton } from '@material-ui/lab';

const CampaignAdvertisersChip: React.FC<{
  definition: CampaignAdvertiserFilterDefinition;
}> = ({ definition }) => {
  const [advertisers = [], loading] = useAdvertisersById(definition.v);
  return (
    <ChipContent title={`Advertiser is`}>
      {loading ? (
        <Skeleton variant="text" width={80} />
      ) : (
        formatList(advertisers.map((a) => a.name))
      )}
    </ChipContent>
  );
};

export const campaignAdvertiserFilterUIDef: CampaignFilterUI = {
  type: 'campaign',
  dimension: 'campaign_advertiser',
  chip: ({ definition }) => {
    if (!definition) return <>Advertiser</>;
    if (definition.k !== 'campaign_advertiser') {
      throw new Error('Invalid definition');
    }
    return <CampaignAdvertisersChip definition={definition} />;
  },
  menu: function CampaignAdvertiserFilterUIMenu({ definition, ...props }) {
    return (
      <CampaignAdvertiserMenu
        definition={definition as CampaignAdvertiserFilterDefinition}
        {...props}
      />
    );
  },
  init: () => ({
    k: 'campaign_advertiser',
    v: []
  }),
  toPredicate: (definition) => {
    if (definition.k !== 'campaign_advertiser') return () => true;
    return (c) =>
      c.advertisers.some((a) => definition.v.includes(a.advertiserId));
  }
};
