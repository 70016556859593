import { FilterDefinition } from '../../../domainTypes/filters';
import React from 'react';
import {
  AnalyticsFilter,
  ISOTimeRange
} from '../../../domainTypes/analytics_v2';
import { Metric } from '../../../services/analyticsV2/metrics';
import { AnalyticsFilterClauseContext } from './useAnalyticsFilters';
import { Campaign } from '../../../features/Campaigns/service';
import { FilterableDimension } from './index';

interface BaseFilterUI {
  dimension: FilterDefinition['k'];
  chip: React.FC<{
    definition?: FilterDefinition;
  }>;
  init: () => FilterDefinition;
}

export type AnalyticsFilterUIContext = {
  baseQuery: {
    filters: Array<AnalyticsFilter>;
    orderBy: Metric;
    range: ISOTimeRange;
  };
};

export type AnalyticsFilterMenuComponentProps<
  T extends FilterDefinition = FilterDefinition
> = {
  isFirst: boolean;
  definition: T;
  onSave: (value: FilterDefinition) => void;
  context: AnalyticsFilterUIContext;
};

export type AnalyticsFilterMenuComponent<
  T extends FilterDefinition = FilterDefinition
> = React.FC<AnalyticsFilterMenuComponentProps<T>>;

export interface AnalyticsFilterUI extends BaseFilterUI {
  type: 'analytics';
  menu: AnalyticsFilterMenuComponent;
  toClauses: (
    definition: FilterDefinition,
    ctx: AnalyticsFilterClauseContext
  ) => Array<AnalyticsFilter>;
}

export type CampaignFilterMenuComponent = React.FC<{
  isFirst: boolean;
  definition: FilterDefinition;
  onSave: (value: FilterDefinition) => void;
}>;

export interface CampaignFilterUI extends BaseFilterUI {
  type: 'campaign';
  menu: CampaignFilterMenuComponent;
  toPredicate: (definition: FilterDefinition) => (c: Campaign) => boolean;
}

export interface FilterUIMenuProps {
  isFirst: boolean;
  definition: FilterDefinition;
  onSave: (value: FilterDefinition) => void;
}

export interface FilterUI {
  chip: React.FC<{
    definition?: FilterDefinition;
  }>;
  menu: React.FC<FilterUIMenuProps>;
  init: () => FilterDefinition;
  dimension: FilterDefinition['k'];
}

export const getFilterUI = (
  uis: Array<FilterUI>,
  dimension: FilterableDimension
) => {
  return uis.find((ui) => ui.dimension === dimension);
};
