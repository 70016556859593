import {
  AnalyticsFilterMenuComponentProps,
  AnalyticsFilterUI
} from '../../FilterUI';
import { UtmFilterDefinition } from '../../../../../domainTypes/filters';
import {
  dbColumn,
  UtmParameterType
} from '../../../../../features/Content/pages/Traffic/Utm/service/utm';
import React, { useState } from 'react';
import { BASIC_MODES, FilterMenu } from '../Menus/FilterMenu';
import { FieldSelectorMenu, useCollectionFilterState } from '../Menus/Selector';
import pluralize from 'pluralize';
import { ChipContent, formatList } from '../common';

const utmDescription = (parameter: UtmParameterType) => {
  switch (parameter) {
    case 'campaign':
      return (
        <>
          <strong>UTM campaign</strong> shows the URL's utm_campaign parameter
          for traffic that landed on the page.
        </>
      );
    case 'medium':
      return (
        <>
          <strong>UTM medium</strong> shows the URL's utm_medium parameter for
          traffic that landed on the page.
        </>
      );
    case 'source':
      return (
        <>
          <strong>UTM source</strong> shows the URL's utm_source parameter for
          traffic that landed on the page.
        </>
      );
    case 'term':
      return (
        <>
          <strong>UTM term</strong> shows the URL's utm_term parameter for
          traffic that landed on the page.
        </>
      );
    case 'content':
      return (
        <>
          <strong>UTM content</strong> shows the URL's utm_content parameter for
          traffic that landed on the page.
        </>
      );
  }
};

const utmTitle = (parameter: UtmParameterType): string => {
  switch (parameter) {
    case 'campaign':
      return 'UTM campaign';
    case 'medium':
      return 'UTM medium';
    case 'source':
      return 'UTM source';
    case 'term':
      return 'UTM term';
    case 'content':
      return 'UTM content';
  }
};

type Props = AnalyticsFilterMenuComponentProps<UtmFilterDefinition> & {
  parameter: UtmParameterType;
};

export const UtmMenu = ({
  parameter,
  definition,
  onSave,
  isFirst,
  context
}: Props) => {
  const [mode, setMode] = useState(BASIC_MODES[0].value);
  const {
    values,
    isSaveEnabled,
    handleSave,
    handleFocus,
    handleToggle
  } = useCollectionFilterState<string, UtmFilterDefinition>(definition, onSave);

  return (
    <FilterMenu>
      <FilterMenu.Header name={utmTitle(parameter)} isFirst={isFirst}>
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <FilterMenu.Body>
        <FieldSelectorMenu
          label={utmTitle(parameter)}
          selectedValues={values}
          onToggle={handleToggle}
          onFocus={handleFocus}
          queryFilters={context.baseQuery.filters}
          analyticsField={dbColumn(parameter)}
          orderBy={context.baseQuery.orderBy}
          range={context.baseQuery.range}
        />
      </FilterMenu.Body>
      <FilterMenu.Footer description={utmDescription(parameter)}>
        <FilterMenu.SaveButton
          disabled={!isSaveEnabled}
          onSave={handleSave}
          label={`Filter by ${pluralize(parameter, values.length, true)}`}
        />
      </FilterMenu.Footer>
    </FilterMenu>
  );
};

const UTMChip = ({
  definition,
  parameter
}: {
  definition?: UtmFilterDefinition;
  parameter: UtmParameterType;
}) => {
  if (!definition) return <>{utmTitle(parameter)}</>;
  if (definition.k !== dbColumn(parameter)) {
    throw new Error('Invalid definition');
  }
  return (
    <ChipContent title={`${utmTitle(parameter)} is`}>
      {formatList(definition.v)}
    </ChipContent>
  );
};

export const utmCampaignFilterUIDef: AnalyticsFilterUI = {
  type: 'analytics',
  dimension: 'utm_campaign',
  chip: ({ definition }) => {
    return (
      <UTMChip
        definition={definition as UtmFilterDefinition}
        parameter="campaign"
      />
    );
  },
  menu: function UtmCampaignFilterUIMenu({ definition, ...props }) {
    return (
      <UtmMenu
        parameter="campaign"
        definition={definition as UtmFilterDefinition}
        {...props}
      />
    );
  },
  init: () => ({ k: 'utm_campaign', v: [] }),
  toClauses: (definition) => {
    if (definition.k !== 'utm_campaign') {
      return [];
    }
    return [
      {
        field: 'utm_campaign',
        condition: 'in',
        values: definition.v
      }
    ];
  }
};

export const utmMediumFilterUIDef: AnalyticsFilterUI = {
  type: 'analytics',
  dimension: 'utm_medium',
  chip: ({ definition }) => {
    return (
      <UTMChip
        definition={definition as UtmFilterDefinition}
        parameter="medium"
      />
    );
  },
  menu: function UtmMediumFilterUIMenu({ definition, ...props }) {
    return (
      <UtmMenu
        parameter="medium"
        definition={definition as UtmFilterDefinition}
        {...props}
      />
    );
  },
  init: () => ({ k: 'utm_medium', v: [] }),
  toClauses: (definition) => {
    if (definition.k !== 'utm_medium') {
      return [];
    }
    return [
      {
        field: 'utm_medium',
        condition: 'in',
        values: definition.v
      }
    ];
  }
};

export const utmSourceFilterUIDef: AnalyticsFilterUI = {
  type: 'analytics',
  dimension: 'utm_source',
  chip: ({ definition }) => {
    return (
      <UTMChip
        definition={definition as UtmFilterDefinition}
        parameter="source"
      />
    );
  },
  menu: function UtmSourceFilterUIMenu({ definition, ...props }) {
    return (
      <UtmMenu
        parameter="source"
        definition={definition as UtmFilterDefinition}
        {...props}
      />
    );
  },
  init: () => ({ k: 'utm_source', v: [] }),
  toClauses: (definition) => {
    if (definition.k !== 'utm_source') {
      return [];
    }
    return [
      {
        field: 'utm_source',
        condition: 'in',
        values: definition.v
      }
    ];
  }
};

export const utmTermFilterUIDef: AnalyticsFilterUI = {
  type: 'analytics',
  dimension: 'utm_term',
  chip: ({ definition }) => {
    return (
      <UTMChip
        definition={definition as UtmFilterDefinition}
        parameter="term"
      />
    );
  },
  menu: function UtmTermFilterUIMenu({ definition, ...props }) {
    return (
      <UtmMenu
        parameter="term"
        definition={definition as UtmFilterDefinition}
        {...props}
      />
    );
  },
  init: () => ({ k: 'utm_term', v: [] }),
  toClauses: (definition) => {
    if (definition.k !== 'utm_term') {
      return [];
    }
    return [
      {
        field: 'utm_term',
        condition: 'in',
        values: definition.v
      }
    ];
  }
};

export const utmContentFilterUIDef: AnalyticsFilterUI = {
  type: 'analytics',
  dimension: 'utm_content',
  chip: ({ definition }) => {
    return (
      <UTMChip
        definition={definition as UtmFilterDefinition}
        parameter="content"
      />
    );
  },
  menu: function UtmContentFilterUIMenu({ definition, ...props }) {
    return (
      <UtmMenu
        parameter="content"
        definition={definition as UtmFilterDefinition}
        {...props}
      />
    );
  },
  init: () => ({ k: 'utm_content', v: [] }),
  toClauses: (definition) => {
    if (definition.k !== 'utm_content') {
      return [];
    }
    return [
      {
        field: 'utm_content',
        condition: 'in',
        values: definition.v
      }
    ];
  }
};

export const ALL_UTM_FILTER_UIS = [
  utmCampaignFilterUIDef,
  utmMediumFilterUIDef,
  utmSourceFilterUIDef,
  utmTermFilterUIDef,
  utmContentFilterUIDef
];
