import { capitalize } from 'lodash';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import { PayoutStatusFilterDefinition } from '../../../../../domainTypes/filters';
import { BASIC_MODES, FilterMenu } from './FilterMenu';
import { EnumSelectorMenu, useCollectionFilterState } from './Selector';
import {
  PAYOUT_STATUSES,
  PayoutStatus
} from '../../../../../domainTypes/performance';
import { AnalyticsFilterMenuComponent } from '../../FilterUI';

export const PayoutStatusMenu: AnalyticsFilterMenuComponent<PayoutStatusFilterDefinition> = ({
  definition,
  context,
  onSave,
  isFirst
}) => {
  const [mode, setMode] = useState(BASIC_MODES[0].value);
  const {
    handleSave,
    isSaveEnabled,
    values,
    handleFocus,
    handleToggle
  } = useCollectionFilterState<PayoutStatus, PayoutStatusFilterDefinition>(
    definition,
    onSave
  );

  return (
    <FilterMenu>
      <FilterMenu.Header name={'payout status'} isFirst={isFirst}>
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <FilterMenu.Body>
        <EnumSelectorMenu
          label={'payout status'}
          options={PAYOUT_STATUSES}
          selectedValues={values}
          onToggle={handleToggle}
          onFocus={handleFocus}
          toLabel={capitalize}
          analyticsField={'payout_status'}
          metric={'commission_sum_net'}
          range={context.baseQuery.range}
          queryFilters={context.baseQuery.filters}
        />
      </FilterMenu.Body>
      <FilterMenu.Footer
        description={
          <>
            <strong>Payout status</strong> of the commission. Disabled options
            means no commissions of that status in the current view.
          </>
        }
      >
        <FilterMenu.SaveButton
          disabled={!isSaveEnabled}
          onSave={handleSave}
          label={`Filter by ${pluralize('payout status', values.length, true)}`}
        />
      </FilterMenu.Footer>
    </FilterMenu>
  );
};
