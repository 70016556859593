import { DeviceFilterDef } from '../../../../../domainTypes/filters';
import { BASIC_MODES, FilterMenu } from './FilterMenu';
import React, { useState } from 'react';
import pluralize from 'pluralize';
import { FieldSelectorMenu, useCollectionFilterState } from './Selector';
import { Device } from '../../../../../domainTypes/tracking';
import { DeviceIconWithLabel } from '../../../../DeviceIcon';
import { AnalyticsFilterMenuComponent } from '../../FilterUI';

export const DeviceMenu: AnalyticsFilterMenuComponent<DeviceFilterDef> = ({
  definition,
  onSave,
  context,
  isFirst
}) => {
  const [mode, setMode] = useState(BASIC_MODES[0].value);
  const {
    values,
    handleSave,
    handleToggle,
    handleFocus,
    isSaveEnabled
  } = useCollectionFilterState<Device, DeviceFilterDef>(definition, onSave);

  return (
    <FilterMenu>
      <FilterMenu.Header name={'device'} isFirst={isFirst}>
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <FilterMenu.Body>
        <FieldSelectorMenu
          label={'device'}
          selectedValues={values}
          onToggle={handleToggle}
          onFocus={handleFocus}
          queryFilters={context.baseQuery.filters}
          analyticsField={'device'}
          orderBy={context.baseQuery.orderBy}
          range={context.baseQuery.range}
          renderOption={(device) => (
            <DeviceIconWithLabel device={device as Device} />
          )}
        />
      </FilterMenu.Body>
      <FilterMenu.Footer
        description={
          <>
            <strong>Device</strong> indicates which type of device the user was
            on when they visited the site or made a purchase.
          </>
        }
      >
        <FilterMenu.SaveButton
          disabled={!isSaveEnabled}
          onSave={handleSave}
          label={`Filter by ${pluralize('device', values.length, true)}`}
        />
      </FilterMenu.Footer>
    </FilterMenu>
  );
};
