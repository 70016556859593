import { Card } from '@material-ui/core';
import { keyBy } from 'lodash';
import moment from 'moment-timezone';
import React, { useCallback, useMemo } from 'react';
import { AlertBox } from '../../../../components/AlertBox';
import {
  ColumnDefinitions,
  TableMetadata,
  useTable
} from '../../../../components/analytics_v2/Table';
import { LINKS_ISSUES_COLUMNS } from '../../../../components/analytics_v2/Table/columnSets';
import {
  TimeframePicker,
  useTimeframe
} from '../../../../components/analytics_v2/Timeframe';
import { CustomPagination } from '../../../../components/CustomPagination';
import { ExportQueryButton } from '../../../../components/ExportQuery';
import { RowsRenderer } from '../../../../components/GroupableList';
import { InlineLoader, Loader } from '../../../../components/Loader';
import { Price } from '../../../../components/Number';
import { SearchInput } from '../../../../components/SearchInput';
import { ColumnSelector } from '../../../../components/Table/ColumnSelector';
import {
  AnalyticsFilter,
  AnalyticsOrderBy,
  AnalyticsQuery,
  AnalyticsResponse,
  AnalyticsResponseRowWithComparison
} from '../../../../domainTypes/analytics_v2';
import { CurrencyCode } from '../../../../domainTypes/currency';
import { ProductCatalogAvailability } from '../../../../domainTypes/productCatalog';
import { css } from '../../../../emotion';
import { useHasComparison } from '../../../../hooks/timeframe';
import { Centered } from '../../../../layout/Centered';
import { FlexContainer } from '../../../../layout/Flex';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarSection
} from '../../../../layout/PageToolbar';
import { useStringQueryParam } from '../../../../routes';
import { useChannelIdGrouper } from '../../../../services/analyticsV2/groups';
import { Metric } from '../../../../services/analyticsV2/metrics';
import {
  toStableAnalyticsV2Link,
  useAnalyticsQueryV2,
  useAnalyticsQueryV2WithAdditionalData
} from '../../../../services/analyticsV2/query';
import { useCurrentUser } from '../../../../services/currentUser';
import {
  LoadingValueExtended,
  useMappedLoadingValue
} from '../../../../services/db';
import { useMixpanel } from '../../../../services/mixpanel';
import { fromMoment } from '../../../../services/time';
import { productCatalogGetItems } from '../../../ProductCatalog/service';
import { LinkActionsMenu } from '../../components/LinkActionsMenu';
import { PageBodyLinks } from '../../components/PageBodyLinks';
import { ProductCatalogDrawer } from '../../components/ProductCatalogDrawer';
import { IssueAvailability } from '../Overview/components/ProductIssue';
import { ProductCellWithoutLink } from '../Overview/components/ProductLinkCell';
import { toProductLinkSearch, useLinksFilters } from '../OverviewV2/service';
import { useContentPagesFilterUIs } from '../../../Content/services/filters';
import { productAvailabilityFilterUIDef } from '../../../../components/analytics_v2/FiltersV2/FilterUI/analytics/ProductAvailabilityFilterUI';
import { useAnalyticsFilters } from '../../../../components/analytics_v2/FiltersV2/useAnalyticsFilters';
import { FiltersToggleButton } from '../../../../components/analytics_v2/FiltersV2/Toggle';
import { FiltersDrawer } from '../../../../components/analytics_v2/FiltersV2/Drawer/FiltersDrawer';
import { FILTER_STATE_LOCAL_STORAGE_KEYS } from '../../../../components/analytics_v2/FiltersV2/Drawer/keys';

const customColumns = [
  'p_catalog_uid',
  'price',
  'availability',
  'actions'
] as const;
type CustomColumns = typeof customColumns[number];
type Column = CustomColumns | Metric;

const availableColumns: Column[] = [
  'p_catalog_uid',
  'price',
  ...LINKS_ISSUES_COLUMNS,
  'availability',
  'actions'
];

const DEFAULT_VISIBLE_COLUMN_NAMES: Metric[] = ['p', 'v', 'c'];

const defaultVisibleColumns: Column[] = [
  ...customColumns,
  ...DEFAULT_VISIBLE_COLUMN_NAMES
];

const columnDefinitionsOld: ColumnDefinitions<CustomColumns> = {
  p_catalog_uid: {
    column: {
      key: 'p_catalog_uid',
      head: () => 'Product',
      cell: (p: AnalyticsResponseRowWithComparison, o: TableMetadata) => {
        return (
          <ProductCellWithoutLink
            p_title={p.group.p_title}
            p_image_url={p.group.p_image_url}
            p_link_url={p.group.p_link_url}
          />
        );
      },
      align: 'left',
      sortable: false,
      defaultDirection: 'asc',
      width: 400,
      flexGrow: 5
    },
    sorter: {
      key: 'p_catalog_uid',
      items: {
        sort: (p: AnalyticsResponseRowWithComparison) => p.group.p_catalog_uid,
        dir: 'asc'
      }
    }
  },
  price: {
    column: {
      key: 'price',
      head: () => 'Price',
      headInfo: () => 'The price of the product this link is pointing to.',
      cell: (p: AnalyticsResponseRowWithComparison, o: TableMetadata) => {
        return (
          <Price
            price={p.group.p_price}
            orig_price={p.group.p_orig_price}
            currency={p.group.p_price_curr as CurrencyCode}
          />
        );
      },
      align: 'right',
      width: 72,
      flexGrow: 1,
      sortable: true
    },
    sorter: {
      key: 'price',
      items: {
        sort: (p: AnalyticsResponseRowWithComparison) => p.group.p_price,
        dir: 'asc'
      }
    }
  },
  availability: {
    column: {
      key: 'availability',
      head: () => 'Stock',
      headInfo: () =>
        'Whether the product this link is pointing to is in stock or not.',
      cell: (p: AnalyticsResponseRowWithComparison, o: TableMetadata) =>
        p.group.p_availability && (
          <FlexContainer fullWidth justifyContent="center">
            <IssueAvailability
              availability={
                p.group.p_availability as ProductCatalogAvailability
              }
              // These are currently returned in a YYYY-MM-DD HH:mm:ss format.
              // We're gonna change this most likely to be true ISO formats,
              // which might require changes of this code.
              seenAt={fromMoment(moment.utc(p.group.p_seen_at))}
              availabilityChangeSeenAt={fromMoment(
                moment.utc(p.group.p_availability_change_seen_at)
              )}
            />
          </FlexContainer>
        ),
      align: 'center',
      width: 80,
      flexGrow: 1,
      sortable: false
    },
    sorter: {
      key: 'availability',
      items: {
        sort: (p: AnalyticsResponseRowWithComparison) => p.group.p_availibility,
        dir: 'asc'
      }
    }
  },
  actions: {
    column: {
      key: 'actions',
      head: () => 'Actions',
      cell: (p: AnalyticsResponseRowWithComparison, o: TableMetadata) => (
        <LinkActionsMenu linkId={p.group.link_id} spaceId={o.spaceId} />
      ),
      align: 'center',
      sortable: false,
      width: 55
    },
    sorter: {
      key: 'actions',
      items: {
        sort: () => '',
        dir: 'asc'
      }
    }
  }
};

const EXPORT_MAX_ROW_COUNT = 1000;
const PAGE_SIZE = 20;

const AVAILABILITY_FILTERS: AnalyticsFilter[] = [
  {
    field: 'p_catalog_uid',
    condition: 'not in',
    values: ['']
  }
];

const useLinksQueryWithCatalogData = (
  filters: AnalyticsFilter[],
  metrics: Metric[],
  paginate: AnalyticsQuery['paginate'],
  orderBy: AnalyticsOrderBy,
  linkSearch = ''
): LoadingValueExtended<AnalyticsResponseRowWithComparison[]> => {
  const { space } = useCurrentUser();
  const tf = useTimeframe();
  const { columnTransformers } = useChannelIdGrouper();
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      groupBy: [
        'p_catalog_uid'
        // 'p_title',
        // 'p_link_url',
        // 'p_image_url',
        // 'p_availability',
        // 'p_seen_at',
        // 'p_availability_change_seen_at',
        // 'p_price',
        // 'p_price_usd',
        // 'p_price_curr',
        // 'p_orig_price',
        // 'p_orig_price_curr'
      ],
      select: metrics,
      ...tf,
      filters,
      search: toProductLinkSearch(linkSearch),
      paginate,
      orderBy: [orderBy],
      columnTransformers: columnTransformers(space)
    };
  }, [
    metrics,
    tf,
    filters,
    paginate,
    orderBy,
    columnTransformers,
    space,
    linkSearch
  ]);

  const additionalDataRequest = useCallback(
    async (
      res: AnalyticsResponse
    ): Promise<AnalyticsResponseRowWithComparison[]> => {
      const catalogIds = res.rows.map((r) => r.group['p_catalog_uid']);
      const catalogItems = await productCatalogGetItems(catalogIds);
      const catalogItemsById = keyBy(catalogItems, (c) => c.uid);
      return res.rows.map((r) => {
        const catalogItem = catalogItemsById[r.group['p_catalog_uid']] || null;
        return {
          ...r,
          group: {
            ...r.group,
            p_title: catalogItem?.title || '',
            p_link_url: catalogItem?.link || '',
            p_image_url: catalogItem?.image_link || '',
            p_seen_at: catalogItem?.seen_at || '',
            p_availability:
              catalogItem?.availability || ProductCatalogAvailability.unknown,
            p_availability_change_seen_at:
              catalogItem?.availability_change_seen_at || '',
            p_price: (catalogItem?.price || 0) as any,
            p_price_usd: (catalogItem?.price_usd || 0) as any,
            p_price_curr: catalogItem?.price_curr || 'USD',
            p_orig_price: (catalogItem?.orig_price || 0) as any,
            p_orig_price_usd: (catalogItem?.orig_price_usd || 0) as any,
            p_orig_price_curr: catalogItem?.orig_price_curr || 'USD'
          }
        };
      });
    },
    []
  );

  return useAnalyticsQueryV2WithAdditionalData(
    space.id,
    query,
    additionalDataRequest,
    {
      logMode: 'compact',
      logLabel: 'useLinksQueryWithCatalogData'
    }
  );
};

const useLinksExportQuery = (
  filters: AnalyticsFilter[],
  metrics: Metric[],
  orderBy: AnalyticsOrderBy,
  linkSearch = ''
) => {
  const { space } = useCurrentUser();
  const { columnTransformers } = useChannelIdGrouper();
  const { range } = useTimeframe();
  return useMemo<AnalyticsQuery>(() => {
    return {
      groupBy: [
        'p_catalog_uid',
        'p_title',
        'p_availability',
        'p_seen_at',
        'p_availability_change_seen_at'
      ],
      select: [...metrics, 'agg_uniq_page_url'],
      range,
      filters,
      search: toProductLinkSearch(linkSearch),
      paginate: {
        page: 1,
        limit: EXPORT_MAX_ROW_COUNT
      },
      orderBy: [orderBy],
      columnTransformers: columnTransformers(space)
    };
  }, [metrics, range, filters, orderBy, columnTransformers, space, linkSearch]);
};

const useLinksCount = (filters: AnalyticsFilter[], linkSearch = '') => {
  const { space } = useCurrentUser();
  const { columnTransformers } = useChannelIdGrouper();
  const { range } = useTimeframe();
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      select: ['count_uniq_link_id'], // Shouldn't this count the catalog ids?
      range,
      filters,
      search: toProductLinkSearch(linkSearch),
      columnTransformers: columnTransformers(space)
    };
  }, [range, filters, columnTransformers, space, linkSearch]);

  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (data) => data.rows[0]?.data.count_uniq_link_id?.curr ?? 0
  );
};

const useAvailableFilters = () => {
  const contentFilters = useContentPagesFilterUIs();
  return useMemo(() => [...contentFilters, productAvailabilityFilterUIDef], [
    contentFilters
  ]);
};

export const InventoryContent = () => {
  const mixpanel = useMixpanel();
  const showComparison = useHasComparison();
  const [productCatalogUid, setProductCatalogUid] = useStringQueryParam(
    'productCatalogUid'
  );
  const {
    tableProps,
    columnSelectorProps,
    paginationSelectorProps,
    pagination,
    orderBy,
    metrics
  } = useTable(availableColumns, columnDefinitionsOld, {
    pageSize: PAGE_SIZE,
    defaultSortColumn: 'c',
    defaultVisibleColumns,
    showComparison,
    trackingEventNames: {
      onSortChange: 'links_inventory_table_sort_change',
      onPageChange: 'links_inventory_table_page_change'
    }
  });
  const filterUIs = useAvailableFilters();
  const { filters, drawerProps, toggleProps } = useAnalyticsFilters(
    filterUIs,
    {
      orderBy: orderBy.field as Metric
    },
    {
      localStorageKey: FILTER_STATE_LOCAL_STORAGE_KEYS.links
    }
  );
  const allFilters = useLinksFilters(filters, AVAILABILITY_FILTERS);
  const [linkSearch, setLinkSearch] = useStringQueryParam('q');

  const [data, loading] = useLinksQueryWithCatalogData(
    allFilters,
    metrics,
    pagination,
    orderBy,
    linkSearch
  );
  const [count] = useLinksCount(allFilters, linkSearch);
  const exportQuery = useLinksExportQuery(
    allFilters,
    metrics,
    orderBy,
    linkSearch
  );
  const exportRowLimitReached = count && count >= EXPORT_MAX_ROW_COUNT;

  return (
    <PageBodyLinks noTopPadding>
      <PageToolbar>
        <PageToolbarSection flex={4}>
          <FlexContainer>
            <SearchInput
              size="small"
              width={360}
              placeholder="Filter by product name or URL"
              value={linkSearch}
              onChange={(nextLinkSearch) => {
                setLinkSearch(nextLinkSearch);
                if (nextLinkSearch) {
                  mixpanel.track('links_inventory_search', {
                    term: nextLinkSearch
                  });
                }
              }}
            />
            <FiltersToggleButton {...toggleProps} />
          </FlexContainer>
        </PageToolbarSection>
        <PageToolbarSection flex={4} justifyContent="flex-end">
          {data && loading && <InlineLoader />}
          <ColumnSelector {...columnSelectorProps} />
          <CustomPagination
            {...paginationSelectorProps}
            siblingCount={0}
            count={Math.ceil((count || 1) / PAGE_SIZE)}
          />
          <TimeframePicker />
          <ExportQueryButton
            title="Export links to CSV"
            reportType="links"
            query={exportQuery}
          >
            {exportRowLimitReached ? (
              <AlertBox variant="error">
                This export will include the first <strong>1000 rows</strong>{' '}
                matching your search criteria. Need more? Please contact the
                Customer Success team for support.
              </AlertBox>
            ) : null}
          </ExportQueryButton>
        </PageToolbarSection>
      </PageToolbar>
      <div
        className={css((t) => ({
          marginBottom: t.spacing(2),
          width: '100%'
        }))}
      >
        <FiltersDrawer {...drawerProps} title="Filter links by" />
      </div>
      {!data ? (
        <Card>
          <Centered height={350}>
            <Loader size={32} />
          </Centered>
        </Card>
      ) : (
        <RowsRenderer
          {...tableProps}
          renderHead={true}
          headProps={{
            sticky: true,
            offset: DEFAULT_OFFSET
          }}
          rows={data}
          onRowClick={(d) => {
            setProductCatalogUid(d.group.p_catalog_uid);
          }}
          rowToKey={toStableAnalyticsV2Link}
        />
      )}
      <ProductCatalogDrawer
        open={!!productCatalogUid}
        onClose={() => setProductCatalogUid('')}
        id={productCatalogUid}
      />
    </PageBodyLinks>
  );
};
