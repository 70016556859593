import { CampaignFilterUI } from './FilterUI';
import { FiltersDrawer } from './Drawer/FiltersDrawer';
import { ComponentProps, useCallback, useMemo } from 'react';
import { FiltersToggleButton } from './Toggle';
import {
  DrawerOptions,
  useFilterDrawerState,
  useFiltersDrawer,
  useFiltersUrlParam
} from './hooks';
import { Campaign } from '../../../features/Campaigns/service';
import { overEvery } from 'lodash';

interface CampaignsFiltersProps {
  predicate: (c: Campaign) => boolean;
  drawerOptions: ComponentProps<typeof FiltersDrawer>;
  toggleProps: ComponentProps<typeof FiltersToggleButton>;
}

export function useCampaignsFilters(
  uis: Array<CampaignFilterUI>,
  drawerOptions?: DrawerOptions
): CampaignsFiltersProps {
  const [filters, setFilters] = useFiltersUrlParam();
  const { isOpen, toggle } = useFilterDrawerState(drawerOptions);
  const filtersDrawerProps = useFiltersDrawer(filters, setFilters);
  const predicate = useCallback(
    (c: Campaign) =>
      overEvery(
        ...filters.map((definition) => {
          const ui = uis.find((ui) => ui.dimension === definition.k);
          if (!ui) return () => true;
          return ui.toPredicate(definition);
        })
      )(c),
    [filters, uis]
  );

  return useMemo(
    () => ({
      predicate,
      drawerOptions: {
        ...filtersDrawerProps,
        uis,
        filters,
        isOpen
      },
      toggleProps: { isOpen, toggle, filters }
    }),
    [filters, filtersDrawerProps, isOpen, predicate, toggle, uis]
  );
}
