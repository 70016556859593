import { AnalyticsFilterUI } from '../../FilterUI';
import React, { useCallback } from 'react';
import { IPostgresTags } from '../../../../../domainTypes/tags';
import { useMappedLoadingValue } from '../../../../../services/db';
import { useTagsForCurrentUser } from '../../../../../services/tags';
import {
  FilterDefinition,
  TagFilterDef
} from '../../../../../domainTypes/filters';
import { ChipContent, formatList, filterModeSuffix } from '../common';
import { TagMenu } from '../Menus/TagMenu';
import { modeToCondition } from '../../useAnalyticsFilters';

const TagsList: React.FC<{ definition: TagFilterDef }> = ({ definition }) => {
  const mapFn = useCallback(
    (tags: IPostgresTags[]) =>
      tags.filter((t) => definition.v.includes(t.id)).map((t) => t.name),
    [definition.v]
  );
  const [tags = []] = useMappedLoadingValue(
    useTagsForCurrentUser(),
    mapFn,
    true
  );
  return <>{formatList(tags)}</>;
};

export const tagFilterUIDef: AnalyticsFilterUI = {
  type: 'analytics',
  dimension: 'tag',
  chip: ({ definition }: { definition?: FilterDefinition }) => {
    if (!definition) return <>Tags</>;
    if (definition.k !== 'tag') {
      throw new Error('Invalid definition');
    }
    return (
      <ChipContent title={`Tag ${filterModeSuffix(definition.mode)}`}>
        <TagsList definition={definition} />
      </ChipContent>
    );
  },
  menu: ({ definition, ...props }) => (
    <TagMenu definition={definition as TagFilterDef} {...props} />
  ),
  init: () => ({
    k: 'tag',
    v: []
  }),
  toClauses: (definition) => {
    if (definition.k !== 'tag') {
      return [];
    }
    return [
      {
        field: 'tags',
        condition: modeToCondition(definition.mode),
        values: definition.v
      }
    ];
  }
};
