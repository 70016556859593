import { AnalyticsFilterUI } from '../../FilterUI';
import { TransactionTypeFilterDefinition } from '../../../../../domainTypes/filters';
import { TransactionTypeMenu } from '../Menus/TransactionTypeMenu';
import { ChipContent, filterModeSuffix, formatList } from '../common';
import { saleTypeLabel } from '../../../../../domainTypes/performance';
import { modeToCondition } from '../../useAnalyticsFilters';

export const transactionTypeFilterUIDef: AnalyticsFilterUI = {
  type: 'analytics',
  dimension: 'transaction_type',
  chip: ({ definition }) => {
    if (!definition) return <>Transaction type</>;
    if (definition.k !== 'transaction_type') {
      throw new Error('Invalid definition');
    }
    return (
      <ChipContent
        title={`Transaction Type ${filterModeSuffix(definition.mode)}`}
      >
        {formatList(definition.v.map(saleTypeLabel))}
      </ChipContent>
    );
  },
  menu: function TransactionTypeFilterUIMenu({ definition, ...props }) {
    return (
      <TransactionTypeMenu
        definition={definition as TransactionTypeFilterDefinition}
        {...props}
      />
    );
  },
  init: () => ({
    k: 'transaction_type',
    v: []
  }),
  toClauses: (definition) => {
    if (definition.k !== 'transaction_type') {
      return [];
    }
    return [
      {
        field: 'sale_type',
        condition: modeToCondition(definition.mode),
        values: definition.v
      }
    ];
  }
};
