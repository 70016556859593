import { AnalyticsFilterUI } from '../../FilterUI';
import { ChipContent, formatList } from '../common';
import { PayoutStatusMenu } from '../Menus/PayoutStatusMenu';
import { PayoutStatusFilterDefinition } from '../../../../../domainTypes/filters';

export const payoutStatusFilterUIDef: AnalyticsFilterUI = {
  type: 'analytics',
  dimension: 'payout_status',
  chip: ({ definition }) => {
    if (!definition) return <>Payout status</>;
    if (definition.k !== 'payout_status') {
      throw new Error('Invalid definition');
    }
    return (
      <ChipContent title="Payout status is">
        {formatList(definition.v)}
      </ChipContent>
    );
  },
  menu: function PayoutStatusFilterUIMenu({ definition, ...props }) {
    return (
      <PayoutStatusMenu
        definition={definition as PayoutStatusFilterDefinition}
        {...props}
      />
    );
  },
  init: () => ({
    k: 'payout_status',
    v: []
  }),
  toClauses: (definition) => {
    if (definition.k !== 'payout_status') {
      return [];
    }
    return [
      {
        field: 'payout_status',
        condition: 'in',
        values: definition.v
      }
    ];
  }
};
