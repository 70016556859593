import { CampaignManagerFilterDefinition } from '../../../../../../domainTypes/filters';
import { BASIC_MODES, FilterMenu } from '../FilterMenu';
import React, { useMemo, useState } from 'react';
import { useCampaigns } from '../../../../../../features/Campaigns/service';
import {
  useCombineLoadingValues,
  useMappedLoadingValue
} from '../../../../../../services/db';
import { compact, uniq } from 'lodash';
import {
  OptionsList,
  SelectorLoader,
  SelectorShell,
  useCollectionFilterState
} from '../Selector';
import pluralize from 'pluralize';
import { toSearchRegexp } from '../../../../../SearchInput';
import { useUsersPerSpace } from '../../../../../../services/user';
import { useCurrentUser } from '../../../../../../services/currentUser';
import { IUser } from '../../../../../../domainTypes/user';
import { CampaignFilterMenuComponent } from '../../../FilterUI';

const Description = () => (
  <>
    <strong>Campaign Manager</strong> is the person responsible for the
    campaign.
  </>
);

const CampaignManagerPicker = ({
  definition,
  managers,
  onSave
}: {
  onSave: (definition: CampaignManagerFilterDefinition) => void;
  definition: CampaignManagerFilterDefinition;
  managers: Array<IUser & { id: string }>;
}) => {
  const {
    values,
    handleSave,
    handleToggle,
    handleFocus,
    isSaveEnabled
  } = useCollectionFilterState<string, CampaignManagerFilterDefinition>(
    definition,
    onSave
  );

  const [search, setSearch] = useState('');
  const managerOptions = useMemo(() => {
    return managers.map((m) => ({
      value: m.id,
      label: m.displayName ?? m.email,
      searchValue: m.displayName.toLowerCase()
    }));
  }, [managers]);
  const options = useMemo(() => {
    const searchRe = toSearchRegexp(search);
    if (!managerOptions) {
      return [];
    }
    if (!searchRe) {
      return managerOptions;
    }
    return managerOptions.filter((o) => o.searchValue.match(searchRe));
  }, [managerOptions, search]);

  return (
    <>
      <FilterMenu.Body>
        <SelectorShell label="Managers" search={search} setSearch={setSearch}>
          <OptionsList
            options={options}
            selectedValues={values}
            onToggle={handleToggle}
            onFocus={handleFocus}
          />
        </SelectorShell>
      </FilterMenu.Body>
      <FilterMenu.Footer description={<Description />}>
        <FilterMenu.SaveButton
          disabled={!isSaveEnabled}
          onSave={handleSave}
          label={`Filter by ${pluralize('manager', values.length, true)}`}
        />
      </FilterMenu.Footer>
    </>
  );
};

const InnerCampaignManagerMenu = ({
  definition,
  onSave
}: {
  onSave: (definition: CampaignManagerFilterDefinition) => void;
  definition: CampaignManagerFilterDefinition;
}) => {
  const { space } = useCurrentUser();

  const [managers, loading] = useMappedLoadingValue(
    useCombineLoadingValues(useCampaigns(), useUsersPerSpace(space.id)),
    ([campaigns, usersDoc]) => {
      const users = usersDoc ? usersDoc.data?.users ?? {} : {};
      return uniq(
        compact(
          campaigns.flatMap((campaign) =>
            campaign.managers.map((m) =>
              users[m]
                ? {
                    ...users[m]?.data,
                    id: m
                  }
                : null
            )
          )
        )
      ) as Array<IUser & { id: string }>;
    }
  );
  if (!managers || loading) {
    return (
      <>
        <FilterMenu.Body>
          <SelectorLoader />
        </FilterMenu.Body>
        <FilterMenu.Footer description={<Description />}>
          <FilterMenu.SaveButton
            disabled={true}
            onSave={() => {}}
            label="Loading managers..."
          />
        </FilterMenu.Footer>
      </>
    );
  }
  return (
    <CampaignManagerPicker
      definition={definition}
      onSave={onSave}
      managers={managers}
    />
  );
};

export const CampaignManagerMenu: CampaignFilterMenuComponent<CampaignManagerFilterDefinition> = ({
  definition,
  onSave,
  isFirst
}) => {
  const [mode, setMode] = useState(BASIC_MODES[0].value);

  return (
    <FilterMenu>
      <FilterMenu.Header name="campaign manager" isFirst={isFirst}>
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <InnerCampaignManagerMenu definition={definition} onSave={onSave} />
    </FilterMenu>
  );
};
