import {
  coerceFilterMode,
  FilterMode,
  ProductAvailabilityFilterDef
} from '../../../../../../domainTypes/filters';
import React, { useState } from 'react';
import { ADVANCED_MODES, FilterMenu } from '../FilterMenu';
import {
  EnumSelectorMenu,
  useCollectionFilterStateWithMode
} from '../Selector';
import pluralize from 'pluralize';
import { ProductCatalogAvailability } from '../../../../../../domainTypes/productCatalog';
import {
  AnalyticsFilter,
  ISOTimeRange
} from '../../../../../../domainTypes/analytics_v2';
import { WithShape } from '../../../../../Charts/Util';
import {
  AVAILABILITY_COLORS,
  AVAILABILITY_TEXTS
} from '../../../../../../features/Links/pages/Overview/components/ProductIssue';
import { AnalyticsFilterMenuComponentProps } from '../../../FilterUI';

const PRODUCT_AVAILABILITY_ALL_STATUSES: ProductCatalogAvailability[] = [
  ProductCatalogAvailability.in_stock,
  ProductCatalogAvailability.out_of_stock,
  ProductCatalogAvailability.preorder,
  ProductCatalogAvailability.backorder,
  ProductCatalogAvailability.unknown
];

const ProductAvailabilityMenuInner: React.FC<{
  definition: ProductAvailabilityFilterDef;
  onSave: (definition: ProductAvailabilityFilterDef) => void;
  filters: Array<AnalyticsFilter>;
  range: ISOTimeRange;
  mode: FilterMode;
  options: ProductCatalogAvailability[];
}> = ({ definition, onSave, filters, range, mode, options }) => {
  const {
    values,
    handleToggle,
    isSaveEnabled,
    handleFocus,
    handleSave
  } = useCollectionFilterStateWithMode<
    ProductCatalogAvailability,
    ProductAvailabilityFilterDef
  >(definition, onSave, mode);

  return (
    <>
      <FilterMenu.Body>
        <EnumSelectorMenu
          label="Product availability"
          options={options}
          selectedValues={values}
          onToggle={handleToggle}
          onFocus={handleFocus}
          queryFilters={filters}
          analyticsField="p_availability"
          range={range}
          toLabel={(availability: ProductCatalogAvailability) => (
            <WithShape
              shape="square"
              color={AVAILABILITY_COLORS[availability].backgroundColor}
            >
              {AVAILABILITY_TEXTS[availability].label}
            </WithShape>
          )}
        />
      </FilterMenu.Body>
      <FilterMenu.Footer
        description={
          <>
            <strong>Product availability</strong> is the current stock status of
            the product.
          </>
        }
      >
        <FilterMenu.SaveButton
          disabled={!isSaveEnabled}
          onSave={handleSave}
          label={`Filter by ${pluralize(
            'availability status',
            values.length,
            true
          )}`}
        />
      </FilterMenu.Footer>
    </>
  );
};

type Props = AnalyticsFilterMenuComponentProps<ProductAvailabilityFilterDef> & {
  options?: ProductCatalogAvailability[];
};

export const ProductAvailabilityMenu: React.FC<Props> = ({
  onSave,
  definition,
  context,
  isFirst,
  options = PRODUCT_AVAILABILITY_ALL_STATUSES
}) => {
  const [mode, setMode] = useState<FilterMode>(
    coerceFilterMode(definition.mode)
  );

  return (
    <FilterMenu>
      <FilterMenu.Header name={'product availability'} isFirst={isFirst}>
        <FilterMenu.ModeSelector
          modes={ADVANCED_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <ProductAvailabilityMenuInner
        key={mode}
        definition={definition}
        onSave={onSave}
        filters={context.baseQuery.filters}
        range={context.baseQuery.range}
        options={options}
        mode={mode}
      />
    </FilterMenu>
  );
};
