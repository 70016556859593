import { Typography } from '@material-ui/core';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { AlertBox } from '../../../../../../components/AlertBox';
import {
  AnalyticsTable,
  ColumnDefinitions,
  useTable
} from '../../../../../../components/analytics_v2/Table';
import { ChartMode } from '../../../../../../components/Charts/EarningsChartCard/ChartModeSelector';
import { GraphMode } from '../../../../../../components/Charts/EarningsChartCard/GraphModeSelector';
import {
  EarningsBarChartCardMetricV2,
  EarningsChartCardWithoutDataV2
} from '../../../../../../components/Charts/EarningsChartCardV2';
import { EarningsPieChartCardV2 } from '../../../../../../components/Charts/EarningsPieChartV2';
import { CustomPagination } from '../../../../../../components/CustomPagination';
import {
  ExportQueryButton,
  useExportQuery
} from '../../../../../../components/ExportQuery';
import { SearchInput } from '../../../../../../components/SearchInput';
import { ColumnSelector } from '../../../../../../components/Table/ColumnSelector';
import {
  AnalyticsFilter,
  AnalyticsQuery,
  AnalyticsResponseRowWithComparison
} from '../../../../../../domainTypes/analytics_v2';
import { EMPTY_ARR } from '../../../../../../domainTypes/emptyConstants';
import { styled } from '../../../../../../emotion';
import { FlexContainer } from '../../../../../../layout/Flex';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarOtherRow,
  PageToolbarSection
} from '../../../../../../layout/PageToolbar';
import {
  useRoutes,
  useStringQueryParam,
  useTypedStringQueryParam
} from '../../../../../../routes';
import { allTime } from '../../../../../../services/analytics';
import { ANALYTICS_GROUPS } from '../../../../../../services/analyticsV2/groups';
import { Metric } from '../../../../../../services/analyticsV2/metrics';
import { useAnalyticsQueryV2 } from '../../../../../../services/analyticsV2/query';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../../../services/currentUser';
import { timeframeToIsoRange, toMoment } from '../../../../../../services/time';
import { useSpaceCurrency } from '../../../../../../services/useSpaceCurrency';
import { PerformancePageBody } from '../../../../components/PerformancePageBody';
import { useKnownAdvertisersV2 } from '../../../../services/hooksV2';
import { compact, isEqual } from 'lodash';
import { useFeatureEnabled } from '../../../../../../services/features';
import {
  TimeframePicker,
  useTimeframe
} from '../../../../../../components/analytics_v2/Timeframe';
import { useHasComparison } from '../../../../../../hooks/timeframe';
import { useIntervalSelectorState } from '../../../../../../components/IntervalSelector';
import { useAnalyticsFilters } from '../../../../../../components/analytics_v2/FiltersV2/useAnalyticsFilters';
import { FiltersToggleButton } from '../../../../../../components/analytics_v2/FiltersV2/Toggle';
import { FiltersDrawer } from '../../../../../../components/analytics_v2/FiltersV2/Drawer/FiltersDrawer';
import { useCustomDimensionsFilterUI } from '../../../../../../components/analytics_v2/FiltersV2/FilterUI/analytics/CustomDimensionsFilterUI';
import { ALL_UTM_FILTER_UIS } from '../../../../../../components/analytics_v2/FiltersV2/FilterUI/analytics/UTMFilterUI';
import { referrerFilterUIDef } from '../../../../../../components/analytics_v2/FiltersV2/FilterUI/analytics/ReferrerFilterUI';
import { payoutStatusFilterUIDef } from '../../../../../../components/analytics_v2/FiltersV2/FilterUI/analytics/PayoutStatusFilterUI';
import { payoutIdFilterUIDef } from '../../../../../../components/analytics_v2/FiltersV2/FilterUI/analytics/PayoutIdFilterUI';
import { channelFilterUIDef } from '../../../../../../components/analytics_v2/FiltersV2/FilterUI/analytics/ChannelFilterUI';
import { platformFilterUIDef } from '../../../../../../components/analytics_v2/FiltersV2/FilterUI/analytics/PlatformFilterUI';
import { advertiserFilterUIDef } from '../../../../../../components/analytics_v2/FiltersV2/FilterUI/analytics/AdvertiserFilterUI';
import { transactionStatusFilterUIDef } from '../../../../../../components/analytics_v2/FiltersV2/FilterUI/analytics/TransactionStatusFilterUI';
import { transactionTypeFilterUIDef } from '../../../../../../components/analytics_v2/FiltersV2/FilterUI/analytics/TransactionTypeFilterUI';
import { deviceFilterUIDef } from '../../../../../../components/analytics_v2/FiltersV2/FilterUI/analytics/DeviceFilterUI';
import { tagFilterUIDef } from '../../../../../../components/analytics_v2/FiltersV2/FilterUI/analytics/TagFilterUIDef';
import { integrationIDFilterUIDef } from '../../../../../../components/analytics_v2/FiltersV2/FilterUI/analytics/IntegrationIDFilterUI';
import { FILTER_STATE_LOCAL_STORAGE_KEYS } from '../../../../../../components/analytics_v2/FiltersV2/Drawer/keys';

const Grid = styled('div')`
  display: grid;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  grid-row-gap: ${(p) => p.theme.spacing(6)}px;
  grid-template-columns: 1.75fr 3fr;
  margin-bottom: ${(p) => p.theme.spacing(6)}px;
  min-height: 520px;

  ${(p) => p.theme.breakpoints.down('md')} {
    grid-column-gap: ${(p) => p.theme.spacing(1)}px;
    grid-row-gap: ${(p) => p.theme.spacing(2)}px;
    grid-template-columns: 1fr 1fr;
  }

  ${(p) => p.theme.breakpoints.down('sm')} {
    grid-column-gap: ${(p) => p.theme.spacing(1)}px;
    grid-row-gap: ${(p) => p.theme.spacing(2)}px;
    grid-template-columns: 1fr;
  }
`;

const customColumns = ['advertiser_name', 'pk'] as const;
type CustomColumns = typeof customColumns[number];
type Column = CustomColumns | Metric;
const columns: Column[] = [
  'order_count_net',
  'quantity_net',
  'aov_net',
  'avg_rate_net',
  'commission_sum_net',
  'gmv_sum_net'
];
const availableColumns: Column[] = [...customColumns, ...columns];
const defaultVisibleColumns: Column[] = [
  ...customColumns,
  'order_count_net',
  'quantity_net',
  'aov_net',
  'avg_rate_net',
  'commission_sum_net',
  'gmv_sum_net'
];
const columnDefinitions: ColumnDefinitions<CustomColumns> = {
  advertiser_name: {
    column: {
      key: 'advertiser_name',
      head: () => 'Advertiser',
      cell: (p) => ANALYTICS_GROUPS.advertiser.toCell(p),
      align: 'left',
      sortable: true,
      width: 200,
      flexGrow: 2
    },
    sorter: {
      key: 'advertiser_name',
      items: {
        sort: (p: AnalyticsResponseRowWithComparison) =>
          p.group['advertiser_name'] || '',
        dir: 'desc'
      }
    }
  },
  pk: {
    column: {
      key: 'pk',
      head: () => 'Platform',
      cell: (p) => ANALYTICS_GROUPS.pk.toContainer(p).label,
      align: 'left',
      sortable: true,
      width: 80,
      flexGrow: 1
    },
    sorter: {
      key: 'pk',
      items: {
        sort: (p: AnalyticsResponseRowWithComparison) => p.group['pk'] || '',
        dir: 'desc'
      }
    }
  }
};

const PAGE_SIZE = 25;

const useAvailableUIs = () => {
  const showTrafficSources = useFeatureEnabled('REFERRER_REPORTS_V1');
  const showCustomDimensions = useFeatureEnabled('CUSTOM_DIMENSIONS');
  const showPayoutDimensions = useFeatureEnabled('PAYOUTS_V1');
  const customDimensionUIs = useCustomDimensionsFilterUI();

  return useMemo(() => {
    return compact([
      ...(showCustomDimensions ? customDimensionUIs : []),
      ...(showTrafficSources ? ALL_UTM_FILTER_UIS : []),
      showTrafficSources ? referrerFilterUIDef : null,
      ...(showPayoutDimensions
        ? [payoutStatusFilterUIDef, payoutIdFilterUIDef]
        : []),
      channelFilterUIDef,
      platformFilterUIDef,
      advertiserFilterUIDef,
      transactionStatusFilterUIDef,
      transactionTypeFilterUIDef,
      deviceFilterUIDef,
      tagFilterUIDef,
      integrationIDFilterUIDef
    ]);
  }, [
    customDimensionUIs,
    showCustomDimensions,
    showPayoutDimensions,
    showTrafficSources
  ]);
};

export const PagePerformanceAdvertisersInnerV2 = () => {
  const { ROUTES } = useRoutes();
  const { space } = useCurrentUser();
  const currency = useSpaceCurrency();

  const uis = useAvailableUIs();
  const { filters, drawerProps, toggleProps } = useAnalyticsFilters(
    uis,
    {
      orderBy: 'commission_sum_net'
    },
    {
      localStorageKey: FILTER_STATE_LOCAL_STORAGE_KEYS.performance
    }
  );

  const [search, setSearch] = useStringQueryParam('q');

  const allFilters = useMemo<AnalyticsFilter[]>(() => {
    if (search && search.length > 2) {
      return [
        ...filters,
        {
          field: 'advertiser_name',
          condition: 'ilike',
          pattern: `%${search}%`
        }
      ];
    }
    return filters;
  }, [filters, search]);

  const { compare, range } = useTimeframe();
  const hasComparison = useMemo(
    () => !isEqual(timeframeToIsoRange(allTime()), range),
    [range]
  );

  const [graphMode, setGraphMode] = useTypedStringQueryParam<GraphMode>(
    'graph_mode',
    'advertiser'
  );
  const [chartMode, setChartMode] = useTypedStringQueryParam<ChartMode>(
    'chart_mode',
    'barChart'
  );
  const intervalSelectorProps = useIntervalSelectorState();
  const [metric, setMetric] = useTypedStringQueryParam<
    EarningsBarChartCardMetricV2
  >('metric', 'commission_sum_net');

  const [advertisers] = useKnownAdvertisersV2(space.id, range, allFilters);
  const pageCount = useMemo(() => {
    if (!advertisers) {
      return 1;
    }
    return Math.ceil((advertisers.length || 0) / PAGE_SIZE);
  }, [advertisers]);

  const [canExportAdvertisers] = useHasCurrentUserRequiredScopes([
    'reports.advertisers.export'
  ]);

  const isNewSpace = moment().diff(toMoment(space.createdAt), 'hours') < 48;

  const showComparison = useHasComparison();
  const {
    tableProps,
    metrics,
    paginationSelectorProps,
    pagination,
    orderBy,
    columnSelectorProps
  } = useTable(availableColumns, columnDefinitions, {
    pageSize: PAGE_SIZE,
    defaultSortColumn: 'commission_sum_net',
    defaultVisibleColumns,
    showComparison
  });

  const q = useMemo<AnalyticsQuery>(() => {
    return {
      range,
      compare: hasComparison ? compare : undefined,
      groupBy: ANALYTICS_GROUPS.advertiser.groupBy,
      filters: allFilters,
      paginate: pagination,
      orderBy: [orderBy],
      select: metrics
    };
  }, [range, hasComparison, compare, allFilters, pagination, orderBy, metrics]);
  const exportQ = useExportQuery(q);
  const tableData = useAnalyticsQueryV2(space.id, q);

  return (
    <PerformancePageBody noTopPadding>
      <PageToolbar sticky offset={DEFAULT_OFFSET} wrap>
        <PageToolbarSection flex={10}>
          <Typography
            variant="h6"
            component="span"
            style={{
              marginRight: '9px',
              position: 'relative',
              fontWeight: 'bold',
              top: '-2px'
            }}
          >
            Advertisers
          </Typography>
          <SearchInput
            value={search}
            onChange={setSearch}
            width={250}
            placeholder="Search by advertiser name"
            size="small"
            autoFocus
          />
          <FiltersToggleButton {...toggleProps} />
        </PageToolbarSection>
        <PageToolbarSection flex={1} justifyContent="flex-end">
          <TimeframePicker />
        </PageToolbarSection>

        <PageToolbarOtherRow>
          <FiltersDrawer {...drawerProps} marginTop={2} />
        </PageToolbarOtherRow>
      </PageToolbar>

      {isNewSpace && (
        <AlertBox variant="success" style={{ marginBottom: '36px' }}>
          Your affiliate network and program-level analytics will begin
          collecting here.
          <br />
          <br />
          In the mean time, try{' '}
          <Link
            style={{ borderBottom: '1px solid black' }}
            to={ROUTES.performanceNew.transactions.url()}
          >
            setting up reporting
          </Link>{' '}
          with your affiliate networks and programs.
        </AlertBox>
      )}
      <div>
        <Grid>
          <EarningsPieChartCardV2
            space={space}
            range={range}
            filters={allFilters}
            currency={currency}
            metric={metric}
            grouper={ANALYTICS_GROUPS.advertiser}
            heading={'Top Advertisers'}
            subheading={'By share of total earnings'}
            aspect={1.3}
          />
          <EarningsChartCardWithoutDataV2
            space={space}
            range={range}
            filters={allFilters}
            search={EMPTY_ARR}
            currency={currency}
            metric={metric}
            interval={intervalSelectorProps.interval}
            setInterval={intervalSelectorProps.setInterval}
            intervalOptions={intervalSelectorProps.options}
            setMetric={setMetric}
            graphMode={graphMode}
            setGraphMode={setGraphMode}
            chartMode={chartMode}
            setChartMode={setChartMode}
          />
        </Grid>

        <FlexContainer justifyContent="space-between">
          <div>
            <Typography
              variant="body1"
              component="p"
              style={{ fontWeight: 'bold' }}
            >
              Advertiser metrics
            </Typography>
            <Typography
              variant="body2"
              component="p"
              color="textSecondary"
              paragraph
            >
              Compare advertiser performance during this period according to
              your key metrics
            </Typography>
          </div>
          <FlexContainer>
            <ColumnSelector {...columnSelectorProps} />
            <CustomPagination
              {...paginationSelectorProps}
              siblingCount={0}
              count={pageCount}
            />
            {canExportAdvertisers && (
              <ExportQueryButton
                query={exportQ}
                reportType="advertisers"
                title="Export advertisers to CSV"
              />
            )}
          </FlexContainer>
        </FlexContainer>

        <AnalyticsTable
          d={tableData}
          tableProps={tableProps}
          rowToKey={ANALYTICS_GROUPS.advertiser.toKey}
          rowToHref={(row) => {
            const advertiserName = row.group['advertiser_name'];
            const pk = row.group['pk'];
            return ROUTES.performanceNew.advertisers.details.url({
              advertisers: advertiserName,
              partners: pk
            });
          }}
        />
      </div>
    </PerformancePageBody>
  );
};
