import React, { useCallback } from 'react';
import { CampaignsPageBody } from '../components/CampaignsPageBody';
import { Campaign, isCampaignActive, useCampaigns } from '../service';
import { Loader } from '../../../components/Loader';
import Typography from '@material-ui/core/Typography';
import { FlexContainer } from '../../../layout/Flex';
import { Helmet } from 'react-helmet';
import { useTrackMixpanelView } from '../../../services/mixpanel';
import { SearchInput } from '../../../components/SearchInput';
import {
  useStringQueryParam,
  useStringQueryParamWithLocalStorage,
  useTypedStringQueryParam
} from '../../../routes';
import { useCurrentUserScopes } from '../../../services/currentUser';
import { NoPermissions } from '../../../components/NoPermissions';
import { CampaignsBoard } from '../components/CampaignsBoard';
import { Menu, Trello } from 'react-feather';
import { CampaignsList } from '../components/list/CampaignsList';
import { useMappedLoadingValue } from '../../../services/db';
import Tooltip from '@material-ui/core/Tooltip';
import { css } from '../../../emotion';
import { ColumnSelector } from '../../../components/Table/ColumnSelector';
import { CampaignsGroupBySelector } from '../components/list/CampaignsGroupBySelector';
import {
  CAMPAIGN_LIST_COLUMN_GROUPS,
  CampaignListColumn,
  defaultColumnsCampaigns
} from '../service/columns';
import { CampaignGroupBy } from '../service/groups';
import { useColumnsQueryParam } from '../../../components/analytics_v2/Table/hooks';
import { Centered } from '../../../layout/Centered';
import { useCampaignsFilters } from '../../../components/analytics_v2/FiltersV2/useCampaignsFilters';
import { campaignTeamFilterUIDef } from '../../../components/analytics_v2/FiltersV2/FilterUI/campaigns/CampaignTeamFilterUI';
import { campaignManagerFilterUIDef } from '../../../components/analytics_v2/FiltersV2/FilterUI/campaigns/CampaignManagerFilterUI';
import { campaignAdvertiserFilterUIDef } from '../../../components/analytics_v2/FiltersV2/FilterUI/campaigns/CampaignAdvertiserFilterUI';
import { campaignStatusFilterUIDef } from '../../../components/analytics_v2/FiltersV2/FilterUI/campaigns/CampaignStatusFilterUI';
import { FiltersToggleButton } from '../../../components/analytics_v2/FiltersV2/Toggle';
import { FiltersDrawer } from '../../../components/analytics_v2/FiltersV2/Drawer/FiltersDrawer';
import { overEvery } from 'lodash';

type Mode = 'board' | 'list';

const ModePicker: React.FC<{ mode: Mode; setMode: (mode: Mode) => void }> = ({
  mode,
  setMode
}) => (
  <FlexContainer>
    <Tooltip title="Board view">
      <Trello
        size={24}
        opacity={mode === 'board' ? 1 : 0.5}
        onClick={() => setMode('board')}
        style={{ cursor: 'pointer' }}
      />
    </Tooltip>
    <Tooltip title="List view">
      <Menu
        size={24}
        opacity={mode === 'list' ? 1 : 0.5}
        onClick={() => setMode('list')}
        style={{ cursor: 'pointer' }}
      />
    </Tooltip>
  </FlexContainer>
);

const ActiveCampaigns: React.FC<{
  mode: Mode;
  campaigns: Campaign[];
  group: CampaignGroupBy;
  columns: Set<CampaignListColumn>;
}> = ({ mode, campaigns, group, columns }) => {
  switch (mode) {
    case 'board':
      return <CampaignsBoard campaigns={campaigns} />;
    case 'list':
      return (
        <CampaignsList campaigns={campaigns} columns={columns} group={group} />
      );
  }
};

const filterUIs = [
  campaignTeamFilterUIDef,
  campaignManagerFilterUIDef,
  campaignAdvertiserFilterUIDef,
  campaignStatusFilterUIDef
];

const matchesSearch = (search: string): ((c: Campaign) => boolean) => {
  if (!search) return () => true;
  return (c: Campaign) => c.name.toLowerCase().includes(search);
};

const useCampaignsFilter = (
  predicate: (c: Campaign) => boolean,
  search: string
): ((c: Campaign[]) => Campaign[]) => {
  return useCallback(
    (cs: Campaign[]) =>
      cs.filter((c) => overEvery([matchesSearch(search), predicate])(c)),
    [predicate, search]
  );
};

export const CampaignsActive: React.FC = () => {
  const [mode, setMode] = useStringQueryParamWithLocalStorage<Mode>(
    'mode',
    'board',
    'campaigns-active-mode'
  );
  const scopes = useCurrentUserScopes();
  const canView = scopes.has('campaigns.view');
  const allActiveCampaigns = useMappedLoadingValue(
    useCampaigns(),
    (campaigns) => campaigns.filter(isCampaignActive)
  );
  const [search, setSearch] = useStringQueryParam('q');
  const [group, setGroup] = useTypedStringQueryParam<CampaignGroupBy>(
    'groupBy',
    'status'
  );
  const [columns, setColumns] = useColumnsQueryParam(
    'columns',
    defaultColumnsCampaigns
  );
  useTrackMixpanelView('view_campaigns_overview');

  const { predicate, toggleProps, drawerOptions } = useCampaignsFilters(
    filterUIs,
    {
      localStorageKey: 'campaigns-active-filter-drawer-state',
      initialState: true
    }
  );

  const filter = useCampaignsFilter(predicate, search);
  const [campaigns, loading] = useMappedLoadingValue(
    allActiveCampaigns,
    filter,
    true
  );

  return (
    <>
      <Helmet>
        <title>Campaigns | Affilimate</title>
      </Helmet>
      <CampaignsPageBody>
        {canView ? (
          <div className={css((t) => ({ marginTop: t.spacing(3) }))}>
            <FlexContainer
              alignItems="center"
              justifyContent="space-between"
              className={css((t) => ({
                marginBottom: t.spacing(2)
              }))}
            >
              <FlexContainer alignItems="center">
                <Typography component="h1" variant="h6">
                  <strong>Active campaigns</strong>
                </Typography>
                <SearchInput
                  value={search}
                  onChange={setSearch}
                  placeholder="Search campaigns"
                  width={300}
                />
                <FiltersToggleButton {...toggleProps} />
              </FlexContainer>
              <FlexContainer>
                {mode === 'list' ? (
                  <>
                    <CampaignsGroupBySelector
                      value={group}
                      onChange={setGroup}
                    />
                    <ColumnSelector
                      value={columns}
                      onChange={setColumns}
                      columns={CAMPAIGN_LIST_COLUMN_GROUPS}
                    />
                  </>
                ) : null}
                <ModePicker mode={mode} setMode={setMode} />
              </FlexContainer>
            </FlexContainer>
            <FiltersDrawer {...drawerOptions} />
            {loading || !campaigns ? (
              <Centered height={500}>
                <Loader size={36} height={500} />
              </Centered>
            ) : (
              <ActiveCampaigns
                campaigns={campaigns}
                mode={mode}
                columns={columns}
                group={group}
              />
            )}
          </div>
        ) : (
          <NoPermissions />
        )}
      </CampaignsPageBody>
    </>
  );
};
