import {
  coerceFilterMode,
  FilterMode,
  TransactionTypeFilterDefinition
} from '../../../../../domainTypes/filters';
import React, { useState } from 'react';
import { ADVANCED_MODES, FilterMenu } from './FilterMenu';
import { EnumSelectorMenu, useCollectionFilterStateWithMode } from './Selector';
import {
  SALE_TYPES,
  SaleType,
  saleTypeLabel
} from '../../../../../domainTypes/performance';
import { pluralize } from '../../../../../services/pluralize';
import { AnalyticsFilter } from '../../../../../domainTypes/analytics_v2';
import { ISOTimeRange } from '../../../../../services/time';
import { AnalyticsFilterMenuComponent } from '../../FilterUI';

const TransactionTypeMenuInner: React.FC<{
  definition: TransactionTypeFilterDefinition;
  onSave: (definition: TransactionTypeFilterDefinition) => void;
  filters: Array<AnalyticsFilter>;
  range: ISOTimeRange;
  mode: FilterMode;
}> = ({ definition, onSave, filters, range, mode }) => {
  const {
    values,
    handleToggle,
    handleFocus,
    isSaveEnabled,
    handleSave
  } = useCollectionFilterStateWithMode<
    SaleType,
    TransactionTypeFilterDefinition
  >(definition, onSave, mode);

  return (
    <>
      <FilterMenu.Body>
        <EnumSelectorMenu
          label="transaction type"
          options={SALE_TYPES}
          selectedValues={values}
          onToggle={handleToggle}
          onFocus={handleFocus}
          toLabel={saleTypeLabel}
          analyticsField={'sale_type'}
          metric={'commission_sum_net'}
          queryFilters={filters}
          range={range}
        />
      </FilterMenu.Body>
      <FilterMenu.Footer
        description={
          <>
            <strong>Transaction type</strong> of the commission. Bonuses are
            filtered out by default. Disabled options means no commissions of
            that type exist in the current view.
          </>
        }
      >
        <FilterMenu.SaveButton
          disabled={!isSaveEnabled}
          onSave={handleSave}
          label={`Filter by ${pluralize(
            'transaction status',
            values.length,
            true
          )}`}
        />
      </FilterMenu.Footer>
    </>
  );
};

export const TransactionTypeMenu: AnalyticsFilterMenuComponent<TransactionTypeFilterDefinition> = ({
  definition,
  onSave,
  context,
  isFirst
}) => {
  const [mode, setMode] = useState(coerceFilterMode(definition.mode));

  return (
    <FilterMenu>
      <FilterMenu.Header name={'transaction type'} isFirst={isFirst}>
        <FilterMenu.ModeSelector
          modes={ADVANCED_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <TransactionTypeMenuInner
        definition={definition}
        key={mode}
        onSave={onSave}
        filters={context.baseQuery.filters}
        range={context.baseQuery.range}
        mode={mode}
      />
    </FilterMenu>
  );
};
