import React, { useMemo } from 'react';
import { Collapse, Paper } from '@material-ui/core';
import { css } from '../../../../emotion';
import { FlexContainer } from '../../../../layout/Flex';
import { FilterDefinition } from '../../../../domainTypes/filters';
import { FilterUI } from '../FilterUI';
import Typography from '@material-ui/core/Typography';
import { AddFilterMenu } from './AddFilterMenu';
import { ClearAll } from './ClearAll';
import { FilterChipsList } from './FilterChipsList';
import { FiltersList, useFiltersList } from './filtersTree';
import { FilterableDimension } from '../index';

const Row: React.FC = ({ children }) => (
  <FlexContainer
    direction="row"
    alignItems="center"
    wrap="wrap"
    className={css((t) => ({
      width: '100%',
      rowGap: t.spacing(1),
      columnGap: t.spacing(2)
    }))}
  >
    {children}
  </FlexContainer>
);

const Container: React.FC<{
  spacing: number;
  marginTop?: number;
  marginBottom?: number;
}> = ({ children, spacing, marginTop, marginBottom }) => (
  <Paper
    className={css((t) => ({
      padding: `${t.spacing(1)}px ${t.spacing(2)}px`,
      marginTop: t.spacing(marginTop || 0),
      marginBottom: t.spacing(marginBottom || 0)
    }))}
  >
    <FlexContainer direction="column" alignItems="start" spacing={spacing}>
      {children}
    </FlexContainer>
  </Paper>
);

export interface FiltersDrawerProps {
  isOpen: boolean;
  title?: string;
  filters: Array<FilterDefinition>;
  setPlaceholderDimension: (d: FilterableDimension) => void;
  placeholderDimension: FilterableDimension | null;
  clearPlaceholder: () => void;
  addFilter: (def: FilterDefinition) => void;
  updateFilter: (def: FilterDefinition) => void;
  removeFilter: (dimension: FilterableDimension) => void;
  clearFilters: () => void;
  uis: Array<FilterUI>;
  marginTop?: number;
  marginBottom?: number;
  tree?: FiltersList;
}

export function FiltersDrawer({
  isOpen,
  title = 'Filter',
  filters,
  setPlaceholderDimension,
  placeholderDimension,
  clearPlaceholder,
  addFilter,
  updateFilter,
  removeFilter,
  clearFilters,
  tree,
  uis,
  marginTop,
  marginBottom
}: FiltersDrawerProps) {
  const defaultFiltersTree = useFiltersList();
  const selectedDimensions = useMemo(() => filters.map((f) => f.k), [filters]);
  // NOTE: We need to render FilterList and in result second Row ahead of time so Placeholder popover has a reference to anchor.
  const hasSomethingInSecondRow =
    filters.length > 0 || placeholderDimension !== null;
  const spacing = hasSomethingInSecondRow ? 1 : 0;
  return (
    <Collapse in={isOpen}>
      <Container
        spacing={spacing}
        marginTop={marginTop}
        marginBottom={marginBottom}
      >
        <Row>
          <Typography
            variant="caption"
            color="textSecondary"
            component="p"
            className={css(() => ({
              textTransform: 'uppercase',
              fontWeight: 'bold'
            }))}
          >
            {title}
          </Typography>
          <AddFilterMenu
            selectDimension={setPlaceholderDimension}
            selectedDimensions={selectedDimensions}
            tree={tree ?? defaultFiltersTree}
            uis={uis}
          />
          <ClearAll filters={filters} clearFilters={clearFilters} />
        </Row>
        <Row>
          <FilterChipsList
            filters={filters}
            placeHolderDimension={placeholderDimension}
            onChange={updateFilter}
            onDelete={removeFilter}
            addFilter={addFilter}
            clearPlaceholder={clearPlaceholder}
            uis={uis}
          />
        </Row>
      </Container>
    </Collapse>
  );
}
