import { Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { MiniTag } from '../../../../../components/MiniTag';
import { Dash } from '../../../../../components/Table/CountCell';
import { Truncated } from '../../../../../components/Truncated';
import { css } from '../../../../../emotion';
import { useRoutes } from '../../../../../routes';
import { getDomainName, getPathName } from '../../../../../services/pages';
import { useRouter } from '../../../../../hooks/useRouter';
import { useFilterDefinitions } from '../../../../../components/analytics_v2/FiltersV2/hooks';

export const PageHref = <Tag extends { id: string; name: string }>({
  href,
  tags = []
}: {
  href?: string;
  tags?: Tag[];
}) => {
  const { ROUTES } = useRoutes();
  const { location } = useRouter();
  const filters = useFilterDefinitions();

  // There can be no href if you try to group by href
  // but there are no hrefs in the data, for example
  // clicks that happened offsite
  if (!href) {
    return <Dash />;
  }

  const pathName = getPathName(href);
  return (
    <Link
      to={ROUTES.content.details.trends.url(href, {
        filters,
        backUrl: location.pathname + location.search
      })}
    >
      <Truncated title={href}>
        <Typography
          className={css((t) => ({
            fontSize: '14px',
            color: t.palette.primary.main,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
          }))}
        >
          {pathName}
        </Typography>
      </Truncated>
      <Typography
        className={css(() => ({
          fontSize: '11px',
          fontWeight: 700,
          color: '#acacb9'
        }))}
      >
        {getDomainName(href)}
      </Typography>

      {!!tags.length && (
        <div style={{ display: 'inline-block', marginTop: '2px' }}>
          {tags.map((tag) => {
            return <MiniTag key={tag.id} label={tag.name} isSelected={false} />;
          })}
        </div>
      )}
    </Link>
  );
};
