import { AnalyticsFilterUI } from '../../FilterUI';
import React from 'react';
import { DeviceMenu } from '../Menus/DeviceMenu';
import { DeviceFilterDef } from '../../../../../domainTypes/filters';
import { ChipContent, formatList } from '../common';

export const deviceFilterUIDef: AnalyticsFilterUI = {
  type: 'analytics',
  dimension: 'device',
  chip: ({ definition }) => {
    if (!definition) return <>Device</>;
    if (definition.k !== 'device') {
      throw new Error('Invalid definition');
    }
    return (
      <ChipContent title="Device is">{formatList(definition.v)}</ChipContent>
    );
  },
  menu: function DeviceFilterUIMenu({ definition, ...props }) {
    return <DeviceMenu definition={definition as DeviceFilterDef} {...props} />;
  },
  init: () => ({
    k: 'device',
    v: []
  }),
  toClauses: (definition) => {
    if (definition.k !== 'device') {
      return [];
    }
    return [
      {
        field: 'device',
        condition: 'in',
        values: definition.v
      }
    ];
  }
};
