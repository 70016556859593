import React, { useMemo, useState } from 'react';
import { CampaignStatusFilterDefinition } from '../../../../../../domainTypes/filters';
import { BASIC_MODES, FilterMenu } from '../FilterMenu';
import { OptionsList, useCollectionFilterState } from '../Selector';
import {
  campaignStatuses,
  campaignStatusTitle
} from '../../../../../../features/Campaigns/service';
import { FlexContainer } from '../../../../../../layout/Flex';
import { CampaignStatusIcon } from '../../../../../../features/Campaigns/components/CampaignStatusIcon';
import pluralize from 'pluralize';
import { css } from '../../../../../../emotion';
import { CampaignFilterMenuComponent } from '../../../FilterUI';

export const CampaignStatusMenu: CampaignFilterMenuComponent<CampaignStatusFilterDefinition> = ({
  definition,
  onSave,
  isFirst
}) => {
  const [mode, setMode] = useState(BASIC_MODES[0].value);
  const {
    values,
    handleToggle,
    handleFocus,
    isSaveEnabled,
    handleSave
  } = useCollectionFilterState<string, CampaignStatusFilterDefinition>(
    definition,
    onSave
  );

  const statuses = useMemo(() => {
    return campaignStatuses.map((status) => ({
      value: status,
      label: (
        <FlexContainer>
          <CampaignStatusIcon status={status} />
          <span>{campaignStatusTitle(status)}</span>
        </FlexContainer>
      )
    }));
  }, []);

  return (
    <FilterMenu>
      <FilterMenu.Header name="status" isFirst={isFirst}>
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <FilterMenu.Body>
        <div
          className={css(() => ({
            overflowY: 'scroll',
            maxHeight: 300,
            display: 'flex',
            flexDirection: 'column'
          }))}
        >
          <OptionsList
            options={statuses}
            selectedValues={values}
            onToggle={handleToggle}
            onFocus={handleFocus}
          />
        </div>
      </FilterMenu.Body>
      <FilterMenu.Footer
        description={
          <>
            <strong>Campaign Status</strong> is the current state of the
            campaign.
          </>
        }
      >
        <FilterMenu.SaveButton
          disabled={!isSaveEnabled}
          onSave={handleSave}
          label={`Filter by ${pluralize('status', values.length, true)}`}
        />
      </FilterMenu.Footer>
    </FilterMenu>
  );
};
