import React, { useMemo } from 'react';
import { TableMetadata } from '../../../../../components/analytics_v2/Table';
import { DeviceIconWithLabel } from '../../../../../components/DeviceIcon';
import {
  AnalyticsField,
  AnalyticsFilter,
  AnalyticsResponseRowWithComparison,
  ISOTimeRange
} from '../../../../../domainTypes/analytics_v2';
import { Device } from '../../../../../domainTypes/tracking';
import {
  useRealtimeColumnTransformers,
  useRealtimeMetric,
  useSmallTableMetrics
} from '../service';
import {
  createNameColumn,
  TopHeader,
  TopPaper,
  TopTable
} from '../../../../../components/analytics_v2/Table/TopTable/TopTable';

const GROUP_FIELD: AnalyticsField = 'device';

const cell = (p: AnalyticsResponseRowWithComparison, o: TableMetadata) => {
  const device = p.group[GROUP_FIELD] as Device;
  return <DeviceIconWithLabel device={device} />;
};

export const TopDevicesTable = ({
  range,
  compare,
  color,
  filters
}: {
  range: ISOTimeRange;
  compare?: ISOTimeRange;
  color: string;
  filters: AnalyticsFilter[];
}) => {
  const [metric] = useRealtimeMetric();
  const metrics = useSmallTableMetrics();
  const columnTransformers = useRealtimeColumnTransformers(filters);
  const deviceColumn = useMemo(
    () => createNameColumn(cell, 'Device', color, metric),
    [color, metric]
  );
  return (
    <TopPaper>
      <TopHeader
        title="Top devices"
        description="Engagement and performance by device type."
      ></TopHeader>
      <TopTable
        filters={filters}
        columnTransformers={columnTransformers}
        metrics={metrics}
        range={range}
        compareRange={compare}
        metric={metric}
        groupField={GROUP_FIELD}
        nameColumn={deviceColumn}
      />
    </TopPaper>
  );
};
