import React, { useCallback, useMemo } from 'react';
import { PageBody, SideNavProps } from '../../../layout/PageBody';
import { compact } from 'lodash';
import { useRoutes } from '../../../routes';
import { useCurrentUserScopes } from '../../../services/currentUser';
import { Lock, Plus } from 'react-feather';
import { isFeatureEnabled } from '../../../services/features';
import { ARTICLE_IDS, Beacon } from '../../../services/beacon';
import { Centered } from '../../../layout/Centered';
import Typography from '@material-ui/core/Typography';
import { SupportButton } from '../../../components/SupportButton';
import { HelpIcon } from '../../../components/HelpIcon';
import { css, styled } from '../../../emotion';

const useCampaignsSideNav = (): SideNavProps => {
  const { ROUTES, goTo } = useRoutes();
  const scopes = useCurrentUserScopes();
  const canView = scopes.has('campaigns.view');
  const canCreate = scopes.has('campaigns.create');
  const canViewTeams = scopes.has('teams.view');

  const openCreator = useCallback(() => {
    goTo(ROUTES.campaigns.create.url());
  }, [ROUTES.campaigns.create, goTo]);

  return useMemo(
    () => ({
      groups: compact([
        canView && {
          label: 'Campaigns',
          items: compact([
            {
              label: 'Active',
              path: ROUTES.campaigns.overview.url()
            },
            {
              label: 'Complete',
              path: ROUTES.campaigns.complete.url()
            },
            {
              label: 'Mine',
              path: '/campaigns/mine',
              disabled: true
            }
          ])
        },
        {
          label: 'Reports',
          items: [
            {
              label: 'Teams',
              path: ROUTES.campaigns.teamsReport.url()
            },
            {
              label: 'Performance',
              path: ROUTES.campaigns.performance.url(),
              disabled: true
            },
            {
              label: 'Managers',
              path: '/campaigns/managers',
              disabled: true
            },
            {
              label: 'Partners',
              path: '/campaigns/history',
              disabled: true
            }
          ]
        },
        {
          label: 'Settings',
          items: compact([
            {
              label: 'Preferences',
              path: ROUTES.campaigns.settings.url(),
              disabled: true
            },
            canViewTeams && {
              label: 'Team',
              path: ROUTES.campaigns.teams.url()
            }
          ])
        }
      ]),
      action: canCreate
        ? {
            icon: Plus,
            label: 'Create',
            onClick: openCreator
          }
        : undefined
    }),
    [
      ROUTES.campaigns.complete,
      ROUTES.campaigns.overview,
      ROUTES.campaigns.performance,
      ROUTES.campaigns.settings,
      ROUTES.campaigns.teams,
      ROUTES.campaigns.teamsReport,
      canCreate,
      canView,
      canViewTeams,
      openCreator
    ]
  );
};

const EmptyStateImage = styled('img')`
  width: 700px;
`;

const CampaignsUpgradePrompt = () => (
  <Centered height={680}>
    <div className={css(() => ({ textAlign: 'center' }))}>
      <EmptyStateImage
        src="/images/empty-states/campaigns-empty-state.png"
        alt="Partnership campaigns"
      />
      <Typography variant="h5" style={{ marginTop: 20 }}>
        <Lock size={18} /> Upgrade to access Partnership Campaigns
      </Typography>
      <Typography
        variant="body1"
        style={{ margin: '20px auto', width: '80%', maxWidth: '500px' }}
      >
        Campaigns help you plan and track how you're delivering on goals for
        your partners, as well as optimize the campaign mid-flight.
      </Typography>
      <SupportButton
        label="Upgrade your account"
        size="large"
        subject="Request account upgrade"
        text="Hi team, I'd like to upgrade my account to access Campaigns."
      />
      <div className={css((t) => ({ marginTop: t.spacing(3) }))}>
        <HelpIcon
          color="gray"
          onClick={() => {
            Beacon('article', ARTICLE_IDS.campaigns.overview);
          }}
        >
          Learn more about campaigns
        </HelpIcon>
      </div>
    </div>
  </Centered>
);

export const CampaignsPageBody: React.FC = ({ children }) => {
  const hasCampaigns = isFeatureEnabled('CAMPAIGNS');
  const sideNav = useCampaignsSideNav();

  if (!hasCampaigns) {
    return <CampaignsUpgradePrompt />;
  }

  return (
    <PageBody sideNav={sideNav} noTopPadding>
      {children}
    </PageBody>
  );
};
