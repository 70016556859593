import { AnalyticsFilterUI } from '../../FilterUI';
import { TransactionStatusFilterDefinition } from '../../../../../domainTypes/filters';
import { SALE_UI_CONFIG } from '../../../../../domainTypes/performance';
import { capitalize } from 'lodash';
import { ChipContent, formatList } from '../common';
import { TransactionStatusMenu } from '../Menus/TransactionStatusMenu';

export const transactionStatusFilterUIDef: AnalyticsFilterUI = {
  type: 'analytics',
  dimension: 'transaction_status',
  chip: ({ definition }) => {
    if (!definition) return <>Transaction status</>;
    if (definition.k !== 'transaction_status') {
      throw new Error('Invalid definition');
    }
    return (
      <ChipContent title="Transaction status is">
        {formatList(
          definition.v.map((status) => SALE_UI_CONFIG[capitalize(status)].label)
        )}
      </ChipContent>
    );
  },
  menu: function TransactionStatusFilterUIMenu({ definition, ...props }) {
    return (
      <TransactionStatusMenu
        definition={definition as TransactionStatusFilterDefinition}
        {...props}
      />
    );
  },
  init: () => ({
    k: 'transaction_status',
    v: []
  }),
  toClauses: (definition) => {
    if (definition.k !== 'transaction_status') {
      return [];
    }
    return [
      {
        field: 'sale_status',
        condition: 'in',
        values: definition.v
      }
    ];
  }
};
