import { CampaignFilterUI } from '../../FilterUI';
import { ChipContent, formatList } from '../common';
import { CampaignManagerMenu } from '../Menus/Campaigns/ManagerMenu';
import { CampaignManagerFilterDefinition } from '../../../../../domainTypes/filters';
import React from 'react';
import { useCurrentUser } from '../../../../../services/currentUser';
import { useMappedLoadingValue } from '../../../../../services/db';
import {
  toUserLabel,
  useUsersInSpaceByIds
} from '../../../../../services/user';
import { Skeleton } from '@material-ui/lab';

const CampaignManagersChip: React.FC<{
  definition: CampaignManagerFilterDefinition;
}> = ({ definition }) => {
  const { space } = useCurrentUser();
  const [usersLabels = [], loading] = useMappedLoadingValue(
    useUsersInSpaceByIds(space.id, definition.v),
    (usersPerSpace) => {
      if (!usersPerSpace) return [];
      return usersPerSpace.map((d) => toUserLabel(d));
    }
  );
  return (
    <ChipContent title={`Campaign manager is`}>
      {loading ? (
        <Skeleton variant="text" width={80} />
      ) : (
        formatList(usersLabels)
      )}
    </ChipContent>
  );
};

export const campaignManagerFilterUIDef: CampaignFilterUI = {
  type: 'campaign',
  dimension: 'campaign_manager',
  chip: ({ definition }) => {
    if (!definition) return <>Campaign manager</>;
    if (definition.k !== 'campaign_manager') {
      throw new Error('Invalid definition');
    }
    return <CampaignManagersChip definition={definition} />;
  },
  menu: function CampaignManagerFilterUIMenu({ definition, ...props }) {
    return (
      <CampaignManagerMenu
        definition={definition as CampaignManagerFilterDefinition}
        {...props}
      />
    );
  },
  init: () => ({
    k: 'campaign_manager',
    v: []
  }),
  toPredicate: (definition) => {
    if (definition.k !== 'campaign_manager') return () => true;
    return (c) => c.managers.some((m) => definition.v.includes(m));
  }
};
