import { compact, uniqBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import {
  AnalyticsFilter,
  AnalyticsQuery,
  ISOTimeRange
} from '../../../../../domainTypes/analytics_v2';
import {
  AdvertiserFilterDefinition,
  coerceFilterMode,
  FilterMode
} from '../../../../../domainTypes/filters';
import { useAnalyticsQueryV2 } from '../../../../../services/analyticsV2/query';
import { useCurrentUser } from '../../../../../services/currentUser';
import { useMappedLoadingValue } from '../../../../../services/db';
import { AdvertiserWithColor } from '../../../../AdvertiserWithColor';
import { ADVANCED_MODES, FilterMenu, saveButtonLabel } from './FilterMenu';
import {
  OptionsList,
  SelectorLoader,
  SelectorShell,
  useCollectionFilterStateWithMode
} from './Selector';
import { AnalyticsFilterMenuComponent } from '../../FilterUI';

interface AdvertiserMenuBodyProps {
  onToggle: (value: string) => void;
  onFocus: (value: string) => void;
  filters: AnalyticsFilter[];
  range: ISOTimeRange;
  value: Array<string>;
}

const LIMIT = 50;

const useAdvertisers = (
  filters: AnalyticsFilter[],
  range: ISOTimeRange,
  search: string
) => {
  const { space } = useCurrentUser();
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      range,
      select: ['commission_sum_net'], // only present in sales table
      groupBy: ['pk', 'advertiser_name'],
      filters: compact([
        ...filters,
        search && {
          field: 'advertiser_name',
          condition: 'ilike',
          pattern: `%${search}%`
        }
      ]),
      orderBy: [{ field: 'commission_sum_net', direction: 'DESC' }],
      paginate: {
        page: 1,
        limit: LIMIT
      }
    };
  }, [filters, range, search]);

  return useMappedLoadingValue(useAnalyticsQueryV2(space.id, query), (res) => {
    // Behold the uniqBy
    // advertiser_name is not unique across pks.
    // The filter does ONLY filter by advertiser_name though, so we just
    // ignore this fact in this case.
    // This is fine, as long as we don't start showing any numbers next to the individial
    // options which then would be misleading.
    return uniqBy(
      res.rows.map((row) => {
        const advertiserName = row.group.advertiser_name;
        const pk = row.group.pk;
        return {
          label: (
            <AdvertiserWithColor
              partnerKey={pk}
              advertiserName={advertiserName}
            />
          ),
          value: advertiserName || 'Unknown',
          searchValue: advertiserName
        };
      }),
      (x) => x.value
    );
  });
};

const AdvertiserMenuBody: React.FC<AdvertiserMenuBodyProps> = ({
  range,
  onToggle,
  filters,
  onFocus,
  value
}) => {
  const [search, setSearch] = useState('');
  const [options, loading] = useAdvertisers(filters, range, search);

  // const maybeHasMore = options.length === LIMIT;

  return (
    <SelectorShell label="Advertisers" search={search} setSearch={setSearch}>
      {!options || loading ? (
        <SelectorLoader />
      ) : (
        <OptionsList
          options={options}
          selectedValues={value}
          onToggle={onToggle}
          onFocus={onFocus}
          caption={`Top advertisers by earnings`}
        />
      )}
    </SelectorShell>
  );
};

const AdvertiserMenuInner: React.FC<{
  definition: AdvertiserFilterDefinition;
  onSave: (v: AdvertiserFilterDefinition) => void;
  filters: AnalyticsFilter[];
  range: ISOTimeRange;
  mode: FilterMode;
}> = ({ definition, onSave, filters, range, mode }) => {
  const {
    values,
    isSaveEnabled,
    handleSave,
    handleToggle,
    handleFocus
  } = useCollectionFilterStateWithMode<string, AdvertiserFilterDefinition>(
    definition,
    onSave,
    mode
  );

  return (
    <>
      <FilterMenu.Body>
        <AdvertiserMenuBody
          filters={filters}
          range={range}
          onFocus={handleFocus}
          onToggle={handleToggle}
          value={values}
        />
      </FilterMenu.Body>
      <FilterMenu.Footer
        description={
          <>
            <strong>Advertiser</strong> is the company or brand that you’re
            partnered with. Options are sorted by earnings.
          </>
        }
      >
        <FilterMenu.SaveButton
          disabled={!isSaveEnabled}
          onSave={handleSave}
          label={saveButtonLabel('advertiser', values.length, mode)}
        />
      </FilterMenu.Footer>
    </>
  );
};

export const AdvertiserMenu: AnalyticsFilterMenuComponent<AdvertiserFilterDefinition> = ({
  definition,
  onSave,
  context,
  isFirst
}) => {
  const [mode, setMode] = useState<FilterMode>(
    coerceFilterMode(definition.mode)
  );

  return (
    <FilterMenu>
      <FilterMenu.Header name={'advertiser'} isFirst={isFirst}>
        <FilterMenu.ModeSelector
          modes={ADVANCED_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <AdvertiserMenuInner
        key={mode}
        onSave={onSave}
        filters={context.baseQuery.filters}
        range={context.baseQuery.range}
        definition={definition}
        mode={mode}
      />
    </FilterMenu>
  );
};
