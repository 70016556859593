import { CampaignAdvertiserFilterDefinition } from '../../../../../../domainTypes/filters';
import React, { useMemo, useState } from 'react';
import { BASIC_MODES, FilterMenu } from '../FilterMenu';
import {
  OptionsList,
  SelectorLoader,
  SelectorShell,
  useCollectionFilterState
} from '../Selector';
import { usePromise } from '../../../../../../hooks/usePromise';
import { useCurrentUser } from '../../../../../../services/currentUser';
import {
  getAdvertisersByIds,
  searchAdvertisers,
  toAdvertiserDoc
} from '../../../../../../services/advertisers';
import pluralize from 'pluralize';
import { AdvertiserLabel } from '../../../../../../features/Campaigns/service/advertiser';
import { CachedAdvertiser } from '../../../../../../domainTypes/advertiser';
import { CampaignAdvertiser } from '../../../../../../domainTypes/campaigns';
import { Doc } from '../../../../../../domainTypes/document';
import { useSecrets } from '../../../../../../services/secret';
import { SECRET_CATEGORY } from '../../../../../../domainTypes/reporting';
import { ISecretWithTs } from '../../../../../../domainTypes/secret';
import { CampaignFilterMenuComponent } from '../../../FilterUI';

const toCampaignAdvertiser = (
  advertiser: Doc<CachedAdvertiser>,
  secrets: Doc<ISecretWithTs>[]
): CampaignAdvertiser & { nickname?: string } => {
  const { data } = advertiser;
  const secret = secrets.find((s) => s.data.instanceId === data.integrationId);
  return {
    advertiserId: data.advertiserId,
    name: data.name,
    partnerKey: data.partnerKey,
    integrationId: data.integrationId,
    nickname: secret?.data.nickname
  };
};

export const useAdvertisersById = (ids: string[]) => {
  const { space } = useCurrentUser();
  const [secrets = []] = useSecrets(space.id, SECRET_CATEGORY);
  return usePromise(async () => {
    if (!ids.length) return [];
    const snapshot = await getAdvertisersByIds(space.id, ids).get();
    return snapshot.docs.map((d) =>
      toCampaignAdvertiser(toAdvertiserDoc(d), secrets)
    );
  }, [ids]);
};

const useAdvertisersByName = (search: string) => {
  const { space } = useCurrentUser();
  const [secrets = []] = useSecrets(space.id, SECRET_CATEGORY);
  return usePromise(async () => {
    const snapshot = await searchAdvertisers(
      space.id,
      search.toLocaleLowerCase()
    ).get();

    return snapshot.docs.map((d) =>
      toCampaignAdvertiser(toAdvertiserDoc(d), secrets)
    );
  }, [search, secrets]);
};

export const CampaignAdvertiserMenu: CampaignFilterMenuComponent<CampaignAdvertiserFilterDefinition> = ({
  definition,
  onSave,
  isFirst
}) => {
  const [mode, setMode] = useState(BASIC_MODES[0].value);
  const [search, setSearch] = useState('');
  const [advertisers, loadingAdvertisers] = useAdvertisersByName(search);

  const {
    values,
    handleSave,
    handleToggle,
    handleFocus,
    isSaveEnabled
  } = useCollectionFilterState<string, CampaignAdvertiserFilterDefinition>(
    definition,
    onSave
  );

  const [selectedAdvertisers] = useAdvertisersById(values);

  const options = useMemo(() => {
    const selected = selectedAdvertisers ? selectedAdvertisers : [];
    if (!advertisers || loadingAdvertisers) {
      return null;
    }
    const promoted = selected.filter(
      (a) => !advertisers.find((b) => a.advertiserId === b.advertiserId)
    );
    return [...promoted, ...advertisers].map((advertiser) => ({
      value: advertiser.advertiserId,
      label: <AdvertiserLabel advertiser={advertiser} />,
      searchValue: advertiser.name
    }));
  }, [advertisers, loadingAdvertisers, selectedAdvertisers]);

  return (
    <FilterMenu>
      <FilterMenu.Header name="advertiser" isFirst={isFirst}>
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <FilterMenu.Body>
        <SelectorShell
          label="Advertisers"
          search={search}
          setSearch={setSearch}
        >
          {!options || loadingAdvertisers ? (
            <SelectorLoader />
          ) : (
            <OptionsList
              options={options}
              selectedValues={values}
              onToggle={handleToggle}
              onFocus={handleFocus}
            />
          )}
        </SelectorShell>
      </FilterMenu.Body>
      <FilterMenu.Footer
        description={
          <>
            <strong>Campaign Advertiser</strong> is the company or brand that
            you’re partnered with.
          </>
        }
      >
        <FilterMenu.SaveButton
          disabled={!isSaveEnabled}
          onSave={handleSave}
          label={`Filter by ${pluralize('Advertiser', values.length, true)}`}
        />
      </FilterMenu.Footer>
    </FilterMenu>
  );
};
