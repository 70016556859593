import { Breadcrumbs, Card, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  ColumnDefinitions,
  useTable
} from '../../../../../components/analytics_v2/Table';
import { useTimeframe } from '../../../../../components/analytics_v2/Timeframe';
import { WithShape } from '../../../../../components/Charts/Util';
import { CustomPagination } from '../../../../../components/CustomPagination';
import { ExportQueryButton } from '../../../../../components/ExportQuery';
import { RowsRenderer } from '../../../../../components/GroupableList';
import { Loader } from '../../../../../components/Loader';
import { ColumnSelector } from '../../../../../components/Table/ColumnSelector';
import { Truncated } from '../../../../../components/Truncated';
import {
  AnalyticsFilter,
  AnalyticsOrderBy,
  AnalyticsQuery,
  AnalyticsResponseRowWithComparison
} from '../../../../../domainTypes/analytics_v2';
import { css } from '../../../../../emotion';
import { Centered } from '../../../../../layout/Centered';
import { FlexContainer } from '../../../../../layout/Flex';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarSection,
  PageToolbarTitle
} from '../../../../../layout/PageToolbar';
import { useRoutes } from '../../../../../routes';
import { Metric } from '../../../../../services/analyticsV2/metrics';
import { useAnalyticsQueryV2 } from '../../../../../services/analyticsV2/query';
import { getStableRandomColor } from '../../../../../services/color';
import { useCurrentUser } from '../../../../../services/currentUser';
import { useMappedLoadingValue } from '../../../../../services/db';
import { getDomainName, getPathName } from '../../../../../services/pages';
import { availableMetrics } from '../service/columns';
import { Slot } from '../service/slot';
import { useContentPagesFilterUIs } from '../../../services/filters';
import { useCustomDimensionTitle } from '../../../../../services/customDimensions';
import { FiltersDrawer } from '../../../../../components/analytics_v2/FiltersV2/Drawer/FiltersDrawer';
import { FiltersToggleButton } from '../../../../../components/analytics_v2/FiltersV2/Toggle';
import { useAnalyticsFilters } from '../../../../../components/analytics_v2/FiltersV2/useAnalyticsFilters';
import { FILTER_STATE_LOCAL_STORAGE_KEYS } from '../../../../../components/analytics_v2/FiltersV2/Drawer/keys';

interface CustomDimensionsDetailsProps {
  slot: Slot;
  value: string;
}

const PAGE_SIZE = 10;

const customColumns = ['page_url'] as const;
type CustomColumns = typeof customColumns[number];
export type Column = CustomColumns | Metric;

const availableColumns: Column[] = [...customColumns, ...availableMetrics];

const defaultVisibleColumns: Column[] = availableColumns;

const columnDefinitions: ColumnDefinitions<CustomColumns> = {
  page_url: {
    column: {
      key: 'page_url',
      head: () => 'Page URL',
      cell: (p: AnalyticsResponseRowWithComparison) => {
        const href = p.group.page_url;
        const pathName = getPathName(href);
        return (
          <>
            <Truncated title={href}>
              <Typography
                className={css((t) => ({
                  fontSize: '14px',
                  color: t.palette.primary.main,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap'
                }))}
              >
                {pathName}
              </Typography>
            </Truncated>
            <Typography
              className={css(() => ({
                fontSize: '11px',
                fontWeight: 700,
                color: '#acacb9'
              }))}
            >
              {getDomainName(href)}
            </Typography>
          </>
        );
      },
      align: 'left',
      sortable: true,
      defaultDirection: 'asc',
      width: 400,
      flexGrow: 8
    },
    sorter: {
      key: 'page_url',
      items: {
        sort: (p: AnalyticsResponseRowWithComparison) => p.group.page_url,
        dir: 'asc'
      }
    }
  }
};

const useSlotValueDetails = (
  slot: Slot,
  value: string,
  filters: AnalyticsFilter[],
  metrics: Metric[],
  pagination: AnalyticsQuery['paginate'],
  orderBy: AnalyticsOrderBy
) => {
  const { space } = useCurrentUser();
  const { range, compare } = useTimeframe();

  const query = useMemo<AnalyticsQuery>(
    () => ({
      select: metrics,
      orderBy: [orderBy],
      paginate: pagination,
      range,
      compare,
      groupBy: ['page_url'],
      filters: [
        ...filters,
        {
          field: 'page_url',
          condition: 'not in',
          values: ['']
        },
        {
          field: slot,
          condition: 'in',
          values: [value]
        }
      ]
    }),
    [compare, filters, metrics, orderBy, pagination, range, slot, value]
  );

  return useAnalyticsQueryV2(space.id, query);
};

const useSlotValueCount = (
  slot: Slot,
  value: string,
  filters: AnalyticsFilter[]
) => {
  const { space } = useCurrentUser();
  const { range } = useTimeframe();

  const query = useMemo<AnalyticsQuery>(
    () => ({
      select: ['count_uniq_page_url'],
      range,
      filters: [
        ...filters,
        {
          field: 'page_url',
          condition: 'not in',
          values: ['']
        },
        {
          field: slot,
          condition: 'in',
          values: [value]
        }
      ]
    }),
    [filters, range, slot, value]
  );

  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (response) => response.rows[0]?.data.count_uniq_page_url?.curr ?? 0
  );
};

const useExportQuery = (
  slot: Slot,
  value: string,
  filters: AnalyticsFilter[],
  metrics: Metric[],
  orderBy: AnalyticsOrderBy
) => {
  const { range } = useTimeframe();

  return useMemo<AnalyticsQuery>(
    () => ({
      select: metrics,
      orderBy: [orderBy],
      range,
      groupBy: ['page_url'],
      filters: [
        ...filters,
        {
          field: 'page_url',
          condition: 'not in',
          values: ['']
        },
        {
          field: slot,
          condition: 'in',
          values: [value]
        }
      ]
    }),
    [filters, metrics, orderBy, range, slot, value]
  );
};

const CustomDimensionsBreadcrumbs = ({
  slot,
  value
}: CustomDimensionsDetailsProps) => {
  const dimensionName = useCustomDimensionTitle(slot);
  const { ROUTES } = useRoutes();
  return (
    <Breadcrumbs>
      <Typography variant="h6" color="textPrimary">
        <Link to={ROUTES.content.customDimensions.slot.url(slot)}>
          {dimensionName}
        </Link>
      </Typography>
      <Typography
        variant="h6"
        color="textPrimary"
        style={{ fontWeight: 'bold' }}
      >
        <WithShape large color={getStableRandomColor(value)}>
          {value}
        </WithShape>
      </Typography>
    </Breadcrumbs>
  );
};

export const CustomDimensionsDetails: React.FC<CustomDimensionsDetailsProps> = ({
  slot,
  value
}) => {
  const uis = useContentPagesFilterUIs();
  const { filters, drawerProps, toggleProps } = useAnalyticsFilters(
    uis,
    {},
    {
      localStorageKey: FILTER_STATE_LOCAL_STORAGE_KEYS.customDimensions
    }
  );

  const {
    tableProps,
    columnSelectorProps,
    paginationSelectorProps,
    pagination,
    orderBy,
    metrics
  } = useTable(availableColumns, columnDefinitions, {
    pageSize: PAGE_SIZE,
    defaultSortColumn: 'commission_sum_net',
    defaultVisibleColumns
  });

  const [data, loading] = useSlotValueDetails(
    slot,
    value,
    filters,
    metrics,
    pagination,
    orderBy
  );

  const [count = 0] = useSlotValueCount(slot, value, filters);

  const exportQuery = useExportQuery(slot, value, filters, metrics, orderBy);

  return (
    <>
      <PageToolbar
        className={css((t) => ({
          paddingTop: 0,
          paddingBottom: t.spacing(1)
        }))}
      >
        <PageToolbarTitle flex={1}>
          <FlexContainer>
            <CustomDimensionsBreadcrumbs slot={slot} value={value} />
            <FiltersToggleButton {...toggleProps} />
          </FlexContainer>
        </PageToolbarTitle>
        <PageToolbarSection flex={1} justifyContent="flex-end">
          <ColumnSelector {...columnSelectorProps} />
          <CustomPagination
            {...paginationSelectorProps}
            count={Math.ceil(count / PAGE_SIZE)}
            siblingCount={0}
          />
          <ExportQueryButton
            title="Export pages to CSV"
            reportType="custom_dimension_details"
            query={exportQuery}
          />
        </PageToolbarSection>
      </PageToolbar>

      <FiltersDrawer {...drawerProps} />

      <div
        className={css((t) => ({
          paddingTop: t.spacing(1),
          paddingBottom: t.spacing(1)
        }))}
      >
        {!data || loading ? (
          <Card>
            <Centered height={350}>
              <Loader size={32}></Loader>
            </Centered>
          </Card>
        ) : data.rows.length === 0 ? (
          <Card>
            <Centered height={350}>
              <Typography variant="h6">No data</Typography>
            </Centered>
          </Card>
        ) : (
          <RowsRenderer
            {...tableProps}
            renderHead={true}
            headProps={{
              sticky: true,
              offset: DEFAULT_OFFSET
            }}
            rows={data.rows}
            rowToKey={(d) => d.group.page_url}
          />
        )}
      </div>
    </>
  );
};
